import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PAX_USAGE_TYPES,
  PAX_USAGE_TYPE_IDLE,
} from 'constants';
import { Aircraft } from 'models';
import { getLopaBodyType } from 'utils';

import {
  Col,
  Row,
} from 'react-bootstrap';
import LOPA from 'components/LOPA';
import UsageDetail from './UsageDetail';
import AircraftDetailsPanel from 'components/default/AircraftDetailsPanel';
import LoadingSpinner from 'components/LoadingSpinner';
import FlightEventsPanel from 'components/FlightEventsPanel';

import styles from './styles.module.css';
import classNames from 'classnames';

const defaultOpenFlightFilters = {};
const defaultClosedFlightFilters = {};
PAX_USAGE_TYPES.getSortedEnumerationItems()
  .forEach((item) => defaultOpenFlightFilters[item.id] = true);
PAX_USAGE_TYPES.getSortedEnumerationItems()
  .forEach((item) => defaultClosedFlightFilters[item.id] = (item.id === PAX_USAGE_TYPE_IDLE.id));

defaultOpenFlightFilters['bluetooth'] = true;
defaultClosedFlightFilters['bluetooth'] = true;

const FlightUsage = (props) => {
  const { aircraft, flight, push } = props;

  const currentFlight = !flight ? aircraft.getCurrentFlight() : flight;
  const isFlightOpen = currentFlight && currentFlight.is_open ? true : false;
  const [isRealTime, setIsRealTime] = useState(isFlightOpen ? true : false);
  const [filters, setFilters] = useState(isFlightOpen ? defaultOpenFlightFilters : defaultClosedFlightFilters);
  const [usageData, setUsageData] = useState(null);

  useEffect(() => {
    setUsageData(flight.getFlightUsageStats(aircraft))
  }, [aircraft, flight]);

  const handleRealTime = (newIsRealTime) => {
    if (newIsRealTime === isRealTime) {
      return;
    }

    const newFilters = newIsRealTime
      ? { ...defaultOpenFlightFilters }
      : { ...defaultClosedFlightFilters };
    newFilters['bluetooth'] = filters['bluetooth'];

    setFilters(newFilters);
    setIsRealTime(newIsRealTime);
  }

  const { lopa: aircraftLopa, aircraft_type: aircraftType } = aircraft;
  let totalITUs = aircraftLopa.getDeviceCount('passenger_displays');

  if (!usageData) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classNames(styles.flightWrapper, getLopaBodyType(aircraftType.value.name))}>
      <LOPA
        mode="usage"
        data={usageData}
        push={push}
        aircraft={aircraft}
        isLatest={isRealTime}
        filters={filters}
      />
      <Row className={styles.infoRow} noGutters>
        <Col md="auto" className={styles.overview}>
          <AircraftDetailsPanel
            aircraft={aircraft}
            flight={flight}
            view="flightDetail"
          />
        </Col>
        <Col lg="auto" className={styles.lopaKey}>
          <UsageDetail
            data={isRealTime ? usageData.stats.current : usageData.stats.lifetime}
            filters={filters}
            filtersHandler={setFilters}
            isRealTime={isRealTime}
            realTimeHandler={handleRealTime}
            totalITUs={totalITUs}
            isFlightOpen={isFlightOpen}
          />
        </Col>
        <Col className={styles.event}>
          <FlightEventsPanel
            key={flight.id}
            flight={flight}
            selectedMode="usage"
          />
        </Col>
      </Row>
    </div>
  );
}

FlightUsage.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  flight: PropTypes.object,
  push: PropTypes.func,
}

export default FlightUsage;
