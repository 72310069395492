import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from 'reducers';
import {
  AIRCRAFT_STATUS,
} from 'constants';
import { AircraftFactory, FlightFactory } from 'classes';
import config from 'config';

require('@skypulse/common/dist/shims.js');

export const filtersTemplate = {
  aircraft_status: AIRCRAFT_STATUS.getSortedEnumerationItems().map((e) => e.id),
  aircraft_type: null,
  connection_status: null,
  departure_airport: null,
  destination_airport: null,
  disconnected: false,
  flight_status: null,
};
const loggerMiddleware = createLogger();

const defaultState = {
  activeAircraft: null,
  activeFlight: null,
  aircraftFilters: filtersTemplate,
  aircraftList: null,
  configuration: {
    maintenance_mode: null,
    ff_global_auth: config.features.globalAuth,
    ff_kiosk_mode: null,
    ff_lrus_and_faults: null,
    ff_rssi_heatmap: null,
    log_level: null,
  },
  currentTime: null,
  debug: false,
  fleetHealth: [],
  flightList: null,
  initialized: false,
  user: null,
  top5LRUTypeCollection: null,
  top5LRUDetail: null,
};

function configureReduxStore(preloadedState) {
  // eslint-disable-next-line
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = [
    thunkMiddleware,
    ...(['development', 'local', 'test'].find((e) => e === process.env.NODE_ENV)
      ? [loggerMiddleware]
      : []),
  ];
  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), ...middleware)),
  );
}

// exports
export const history = createBrowserHistory();

const initialState = Object.assign({}, defaultState, JSON.parse(sessionStorage.getItem('state')));
const {
  activeAircraft,
  activeFlight,
  aircraftList,
  flightList,
} = initialState;

if (aircraftList && aircraftList.length) {
  initialState.aircraftList = aircraftList.map((aircraftData) => AircraftFactory.getAircraft(aircraftData));
}

if (activeAircraft) {
  initialState.activeAircraft = AircraftFactory.getAircraft(activeAircraft);
}

if (flightList && flightList.length) {
  initialState.flightList = flightList.map((flightData) => FlightFactory.getFlight(flightData));
}

if (activeFlight) {
  initialState.activeFlight = FlightFactory.getFlight(activeFlight);
}

const store = configureReduxStore(initialState);
export default store;

function saveState() {
  const state = store.getState();
  const exportedState = {};

  const getExportedValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((val) => getExportedValue(val));
    }

    if (Object.isObject(value) && value.export) {
      return value.export();
    }

    return value;
  };

  Object.entries(state).forEach(([key, value]) => {
    switch (key) {
      // ignore these keys
      case 'configuration':     // Configuration should be fetched on every request
      case 'debug':             // Debug menu should be closed on each refresh
      case 'initialized':       // should always be false to allow initialization
      case 'router':            // handled by react-router / browser history
      case 'user':              // user should be re-evaluated on every refresh
        break;
      default:
        exportedState[key] = getExportedValue(value);
        break;
    }
  });

  sessionStorage.setItem('route', state.router.location.pathname);
  sessionStorage.setItem('state', JSON.stringify(exportedState));
}

let storeCacheTimeout;
store.subscribe(() => {
  clearTimeout(storeCacheTimeout);
  storeCacheTimeout = setTimeout(saveState, 1000);
});
