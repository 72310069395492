import { API as AmplifyAPIRequestHandler } from '@aws-amplify/api';
import { MockAPIRequestHandler } from './MockAPIRequestHandler';

import config from 'config';

AmplifyAPIRequestHandler.configure(config.amplify);

function mergeOptions() {
  const options = {};
  // loop through all the arguments
  // each argument is a map of options
  for (let x = 0; x < arguments.length; x++) {
    const overrideOptions = arguments[x];
    Object.keys(overrideOptions).forEach((key) => {
      const value = overrideOptions[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        options[key] = Object.assign({}, options[key], value);
      } else {
        options[key] = value;
      }
    });
  }
  return options;
}

function APIResponseHandler(promise) {
  const originalPromise = promise;
  const thens = [];
  const catches = [];

  const mainPromise = new Promise((resolve, reject) => {
    (async () => {
      try {
        let result = await promise;
        for (let i = 0; i < thens.length; i++) {
          result = await thens[i](result);
        }
        resolve(result);
      } catch (e) {
        for (let i = 0; i < catches.length; i++) {
          await catches[i](e);
        }
      }
    })();
  });

  mainPromise.then = (fn) => {
    thens.push(fn);
    return mainPromise;
  };

  mainPromise.catch = (fn) => {
    catches.push(fn);
    return mainPromise;
  };

  mainPromise.cancel = (message = 'API request canceled') => {
    AmplifyAPIRequestHandler.cancel(originalPromise, message);
  };

  return mainPromise;
}

export class APIRequestHandler {
  constructor(apiName, defaultOptions = {}) {
    this.apiName = apiName;

    // extract non-AXIOS specific options
    this.offline = defaultOptions.offline || false;
    this.mocks = defaultOptions.mocks || undefined;
    delete defaultOptions.offline;
    delete defaultOptions.mocks;

    this.defaultOptions = mergeOptions(
      {
        response: true,           // AXIOS will return the full response object instead of just response.data
      },
      defaultOptions,
    );
  }

  request = (method, path, requestOptions = {}) => {
    // switch handler based on offline
    let apiHandler;
    if (!this.offline) {
      apiHandler = AmplifyAPIRequestHandler;
      // AWS axios implementation renames delete to del
      if (method === 'delete') method = 'del';
    } else {
      apiHandler = MockAPIRequestHandler;
      apiHandler.mocks = this.mocks;
    }

    // merge options
    const mergedOptions = mergeOptions(this.defaultOptions, requestOptions);

    // make request
    const promise = APIResponseHandler(apiHandler[method](this.apiName, path, mergedOptions));

    promise.then((response) => {
      // todo: add logic to check response codes
      const serviceName = response.config.path.split('?')[0].split('/')[2];
      const version = response.headers['x-api-version'] || '<unversioned>';
      config.services[serviceName] = version
      return response;
    });

    promise.catch((error) => {
      if (AmplifyAPIRequestHandler.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    });

    return promise;
  };
}
