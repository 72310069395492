import { DFPAPILambdaJSONResponse, DFPAPILambdaJSONErrorResponse, DFPAPILambdaError } from 'classes';
import { FLIGHTS } from './data';

export function getFlight(data = {}) {
  const { queryStringParameters: { fid } } = data;

  let flighttData;
  if (fid) {
    flighttData = FLIGHTS.find((ft) => ft.id === fid);
  }

  if (flighttData) {
    return new DFPAPILambdaJSONResponse(flighttData);
  }

  return new DFPAPILambdaJSONErrorResponse(new DFPAPILambdaError('Flight not found', 404));
}

export function postFlights(data = {}) {
  const { body, queryStringParameters: { all, num } } = data;
  const { fids } = (body) ? body : {};

  let flighttData;
  if (all) {
    flighttData = [...FLIGHTS];
  } else if (fids) {
    flighttData = FLIGHTS.filter((ft) => fids.find((fid) => ft.id === fid));
  } else if (num) {
    flighttData = FLIGHTS.filter((ft) => ft.nm === num);
  }

  if (flighttData && Array.isArray(flighttData) && flighttData.length > 0) {
    return new DFPAPILambdaJSONResponse({ flights: flighttData });
  }

  return new DFPAPILambdaJSONErrorResponse(new DFPAPILambdaError('Flight not found', 404));
}
