import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form
} from 'react-bootstrap';
import styles from './styles.module.css'


const TextInput = forwardRef((props, ref) => {
  const {
    classname,
    cols,
    containerClassName,
    disabled,
    error,
    helpText,
    icon,
    idPrefix,
    label,
    labelClassName,
    maxLength,
    minLength,
    name,
    noGutters,
    onChange,
    onClick,
    placeholder,
    readOnly,
    rows,
    type,
    isTextarea,
    value,
    ...passProps
  } = props;

  const id = `${idPrefix}-Text-Input`;

  return (
    <Form.Group className={`${styles.root} ${containerClassName}`} controlId={id}>
      <Row className={styles.labelInput} noGutters={!noGutters ? false : noGutters}>
        {
          !label
            ? null
            : (
              <Form.Label
                column
                md="auto"
                className={`label ${styles.label} ${labelClassName} ${error ? 'labelError' : null}`}
              >
                {label}
              </Form.Label>
            )}
        <Col className={styles.input}>
          {
            isTextarea ?
              (
                <Form.Control
                  as="textarea"
                  name={name}
                  rows={rows}
                  cols={cols}
                  value={value || ''}
                  placeholder={placeholder}
                  onChange={onChange}
                  isInvalid={error}
                  disabled={disabled}
                  readOnly={readOnly}
                  onClick={onClick}
                  className={classname}
                  maxLength={maxLength}
                  minLength={minLength}
                  {...passProps}
                />
              ) : (
                <Form.Control
                  ref={ref}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  onChange={onChange}
                  isInvalid={error}
                  disabled={disabled}
                  readOnly={readOnly}
                  onClick={onClick}
                  className={classname}
                  value={type === 'file' ? undefined : value || ''}
                  maxLength={maxLength}
                  minLength={minLength}
                  {...passProps}
                />
              )
          }
          {
            !icon
              ? null
              : <img className={styles.icon} src={icon} alt="" />
          }
          {
            !error
              ? null
              : (
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              )
          }
          {
            helpText
              ? (
                <Row>
                  <Col>
                    <Form.Text> {helpText} </Form.Text>
                  </Col>
                </Row>
              )
              : null
          }
        </Col>
      </Row>
    </Form.Group>
  )
});

TextInput.propTypes = {
  classname: PropTypes.string,
  cols: PropTypes.number,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  icon: PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'file']),
  isTextarea: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextInput;
