/* eslint-disable no-use-before-define */

import { DFPSingleton } from '@dfp/common';

import {
  flightGETById,
  flightsGETByNumber,
  flightsGETAll,
} from 'libs/api';
import {
  Flight,
} from 'models';
import store from 'store';

export class FlightFactory extends DFPSingleton {
  static getFlight(flightData) {
    return new Flight(flightData);
  }

  static getFlightById(fid, dt) {

    return flightGETById(fid, dt)
      .then((response) => {
        // get flight from backend
        let { data: { error, result } } = response;
        if (error) {
          console.warn(`Error retrieving flight: ${error}`);
        }
        if (result !== 'OK') {
          return FlightFactory.getFlight(result);
        }
      });
  }

  static async getFlightsByNumber(num, dt) {
    // get flight from backend
    let { data: { error, result } } = await flightsGETByNumber(num, dt);
    if (error) {
      console.warn(`Error retrieving flight: ${error}`);
    }
    return result.map((flightData) => FlightFactory.getFlight(flightData));
  }

  static async getAllFlights(dt, page, pageSize) {
    // get flight from backend
    let { data: { error, result: flights, ct } } = await flightsGETAll(dt, page, pageSize);
    if (error) {
      console.warn(`Error retrieving flight: ${error}`);
    }
    const flightList = flights.map((flightData) => FlightFactory.getFlight(flightData));
    return { flightList, ct };
  }

  static getSummaryFlightById(fid) {
    const { flightList } = store.getState();
    if (flightList && Array.isArray(flightList)) {
      return flightList.find((ft) => ft.id === fid);
    }
  }
}
