import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push, goBack, replace } from 'react-router-redux';
import classnames from 'classnames';

import {
  setActiveAircraft,
  clearActiveFlight,
  setActiveFlight,
} from 'actions';
import { NOT_APPLICABLE, PATHS } from 'constants';
import { convertMinutesToTime, getLopaBodyType } from 'utils';
import { Col, Row, Button } from 'react-bootstrap';
import ShipSelect from 'components/default/ShipSelect';
import LeftNav from 'components/default/LeftNav';
import ShipContent from './ShipContent';
import ShipOverview from './ShipOverview';
import Configuration from './Configuration';

// Configuration
import CrewPanels from './ShipConfiguration/CrewPanels';
import PublicDisplays from './ShipConfiguration/PublicDisplays';
import PowerSupplies from './ShipConfiguration/PowerSupplies';
import PCUs from './ShipConfiguration/PCUs';
import Servers from './ShipConfiguration/Servers';
import ContentLoaders from './ShipConfiguration/ContentLoaders';
import WAPs from './ShipConfiguration/WAPs';
import PassengerDisplays from './ShipConfiguration/PassengerDisplays';
import styles from './styles.module.css';

const Ship = (props) => {
  const {
    clearActiveFlight,
    currentTime,
    goBack,
    match: { params },
    push,
    rActiveAircraft,
    rAircraftList,
    replace,
    rFlightList,
    setActiveAircraft,
    setActiveFlight,
  } = props;

  const [showShipSelect, setShowShipSelect] = useState(false);
  const [lruId, setLruId] = useState(params && params.page && params.lru_pos ? params.lru_pos : null);

  useEffect(() => {
    if (params?.lru_pos) {
      setLruId(params.lru_pos)
    }
  }, [params]);

  if (!rActiveAircraft || showShipSelect) {
    return (
      <ShipSelect onCancel={() => {
        if (rActiveAircraft) {
          setShowShipSelect(false)
        } else {
          goBack();
        }
      }} onSelect={(aircraft) => {
        setActiveAircraft(aircraft);
        clearActiveFlight();
        setShowShipSelect(false)
      }} />
    );
  }

  const { lopa: aircraftLopa } = rActiveAircraft;
  const { headendMap } = (aircraftLopa) ? aircraftLopa.getConfiguration() : [];
  const configMenu = [];
  headendMap.forEach((type) => {
    const { name, devices } = type;
    if (devices.length < 1) return null;
    const id = name.toLowerCase().replace(/\W/g, '_');
    configMenu.push({
      display: name,
      id: id,
    });
  });

  // Manually pushing passenger Displays and PCUs to the left Nav
  // As they are not the part od headend map.
  if (aircraftLopa.passenger_displays.length > 0) {
    configMenu.push({
      display: 'Passenger Displays',
      id: 'passenger_displays',
    });
  }
  if (aircraftLopa.pcus.length > 0) {
    configMenu.push({
      display: 'PCUs',
      id: 'pcus',
    });
  }

  // Sorting ship configuration menu
  configMenu.sort((a, b) => a.display.localeCompare(b.display));

  const NavMap = {
    'Status': [
      {
        display: 'Overview',
        id: 'overview'
      },
      {
        display: 'Features',
        id: 'features',
      }
    ],
    'Content': [
      {
        display: 'Content status',
        id: 'content',
      },
    ],
    'Configuration': configMenu
  };

  const activeMenuItem = params && params.page ? params.page : 'overview'

  const renderMenuItem = () => {
    if (activeMenuItem === 'configuration') {
      replace(`${PATHS.ship}/overview`)
    }
    switch (activeMenuItem) {
      case 'crew_panels': return <CrewPanels aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'passenger_displays': return <PassengerDisplays aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'power_supplies': return <PowerSupplies aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'public_displays': return <PublicDisplays aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'pcus': return <PCUs aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'servers': return <Servers aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'content_loaders': return <ContentLoaders aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'waps': return <WAPs aircraft={rActiveAircraft} onRowClick={setLruId} selectedLru={lruId} push={push} />
      case 'content': return <ShipContent aircraft={rActiveAircraft} />
      case 'features':
        return <Configuration aircraft={rActiveAircraft} push={push} />
      case 'overview':
      default:
        return <ShipOverview
          aircraft={rActiveAircraft}
          flights={aircraftFlights}
          push={push}
          clearActiveFlight={clearActiveFlight}
          setActiveFlight={setActiveFlight}
          aircraftList={rAircraftList}
        />
    }
  }

  let headerText = `${rActiveAircraft.tail} - Configuration`;
  if (activeMenuItem === 'overview' || activeMenuItem === 'content' || activeMenuItem === 'features') {
    headerText = `${rActiveAircraft.tail} - ${activeMenuItem}`;
  }

  const latestHistory = rActiveAircraft.getLatestHistory() || {};
  const { created: lastEventTimestamp } = latestHistory;
  const lastUpdateTime = (lastEventTimestamp) ? `${convertMinutesToTime((currentTime - lastEventTimestamp) / 1000 / 60)} ago` : NOT_APPLICABLE;

  const aircraftTypeName = (rActiveAircraft.aircraft_type) ? rActiveAircraft.aircraft_type.value.name : '';

  const aircraftFlights = (rFlightList)
    ? rFlightList.reduce((arr, flight) => {
      if (flight.aircraft_id === rActiveAircraft.id) {
        arr.push(flight);
      }
      return arr;
    }, [])
    : [];

  return (
    <Row className={classnames(styles.root, getLopaBodyType(aircraftTypeName))} data-cy-id="Ship" noGutters>
      <Col md="auto" className={styles.leftPanel}>
        <LeftNav
          activeMenuItem={activeMenuItem}
          changeActiveMenu={(id) => {
            push(`${PATHS.ship}/${id}`)
            setLruId(null);
          }}
          navMap={NavMap}
          primaryHeader={`Tail ${rActiveAircraft.tail}`}
          secondaryHeader={aircraftTypeName}
          testIdPrefix="Ship"
        />
      </Col>
      <Col className={styles.container}>
        <Row className={styles.headerContainer} noGutters>
          <Col data-cy-id="Ship-Header" md="auto" className={styles.header}>
            {headerText}
          </Col>
          <Col md="auto">
            <Row className={styles.tailChange} noGutters>
              <Col data-cy-id="Ship-DataFrom" md="auto">
                <span className={styles.dataFrom}>Data from:</span> {lastUpdateTime}
              </Col>
              <Col md="auto">
                {
                  (
                    <Button
                      className={`button_reg ${styles.headerButton}`}
                      data-cy-id="Ship-ShipReportButton" onClick={() => push(`${PATHS.reportShip}/${rActiveAircraft.id}`)}
                    >
                      Ship reports
                    </Button>
                  )
                }
              </Col>
              <Col md="auto">
                <Button className={`button_foc ${styles.headerButton}`} data-cy-id="Ship-ChangeTailButton" onClick={() => setShowShipSelect(true)}>Change tail</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {renderMenuItem()}
      </Col>
    </Row>
  );
}

const state = (state) => {
  return ({
    currentTime: state.currentTime,
    rActiveAircraft: state.activeAircraft,
    rAircraftList: state.aircraftList,
    rFlightList: state.flightList,
  });
};

const actions = {
  setActiveAircraft,
  clearActiveFlight,
  setActiveFlight,
  push,
  goBack,
  replace,
};

export default connect(state, actions)(Ship);
