import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import iconHeadsetLight from "assets/icons/iconHeadsetLight.png";
import { PAX_USAGE_TYPES } from 'constants';

import {
  Row,
  Col,
} from 'react-bootstrap';
import ButtonItem from 'components/inputs/ButtonItem';

import styles from './styles.module.css';

const UsageDetail = (props) => {
  const {
    data,
    filters,
    filtersHandler,
    isRealTime,
    realTimeHandler,
    totalITUs,
    isFlightOpen
  } = props;

  const [statsDisplayMode, setStatsDisplayMode] = useState('number');

  const handleFilterChange = (e) => {
    const id = e.target.value;
    const localFilters = Object.assign({}, filters);
    if (isRealTime && isFlightOpen) {
      localFilters[id] = !localFilters[id];
    } else {
      Object.keys(localFilters).forEach((fid) => {
        if (fid !== 'bluetooth') {
          localFilters[fid] = (fid === id);
        }
      });
    }
    filtersHandler(localFilters);
  }

  const idPrefix = 'UsageDetail';

  return (
    <Col className={styles.root} data-cy-id="Flights-UsagePanel">
      <Row noGutters>
        {
          isFlightOpen
            ? (
              <Col className={styles.header} data-cy-id="Flights-UsagePanel-Header">
                LOPA is displaying: Usage:&nbsp;
                <span
                  data-cy-id="Flights-UsagePanel-LatestLink"
                  className={isRealTime ? styles.headerValue : styles.headerLink}
                  onClick={() => realTimeHandler(true)}
                >
                  Real Time
                </span>
                &nbsp;|&nbsp;
                <span
                  data-cy-id="Flights-UsagePanel-EntireFlightLink"
                  className={isRealTime ? styles.headerLink : styles.headerValue}
                  onClick={() => realTimeHandler(false)}
                >
                  Entire Flight
                </span>
              </Col>
            )
            : (
              <Col className={styles.header} data-cy-id="Flights-UsagePanel-Header">
                LOPA is displaying:&nbsp;
                <span className={styles.headerValue} >
                  Usage entire flight
                </span>
              </Col>
            )
        }
      </Row>
      <Row noGutters className={styles.LRUCountContainer}>
        <Col data-cy-id="Flights-UsagePanel-LruCountSubtitle" className={styles.LRUCount}>
          {totalITUs} Pax
        </Col>
        <Col className={styles.radioButtonContainer}>
          <ButtonItem
            label="Number"
            value="number"
            onChange={() => setStatsDisplayMode('number')}
            type="radio"
            classname={styles.buttonItem}
            labelClassName={styles.buttonItemLabel}
            isChecked={statsDisplayMode === 'number'}
            idPrefix={`${idPrefix}-number`}
            data-cy-id="Flights-UsagePanel-NumberInput"
          />
          <ButtonItem
            label="Percent"
            value="percent"
            onChange={() => setStatsDisplayMode('percent')}
            type="radio"
            classname={styles.buttonItem}
            labelClassName={styles.buttonItemLabel}
            isChecked={statsDisplayMode === 'percent'}
            idPrefix={`${idPrefix}-percent`}
            data-cy-id="Flights-UsagePanel-PercentInput"
          />
        </Col>
      </Row>
      <>
        {
          PAX_USAGE_TYPES.getSortedEnumerationItems().map((item, i) => {
            const { id, value: { name } } = item;
            const isChecked = (filters[id]);

            let displayName = (name === 'Idle' && !isRealTime) ? 'Unused' : name;
            let filterClassName = styles.legendBlockInactive;
            switch (name) {
              case 'Map':
                filterClassName = styles.legendBlockMap;
                break;
              case 'Video':
                filterClassName = styles.legendBlockVideo;
                break;
              case 'Audio':
                filterClassName = styles.legendBlockAudio;
                break;
              case 'Live TV':
                filterClassName = styles.legendBlockLiveTV;
                break;
              case 'Games':
                filterClassName = styles.legendBlockGame;
                break;
              default:
                filterClassName = styles.legendBlockIdle;
                break;
            }

            const className = classnames(styles.legendBlock, isRealTime && isFlightOpen && !isChecked ? styles.legendBlockUnSelected : filterClassName);
            const count = data[id] || 0;
            const percent = ((count > 0) ? Math.round(count / totalITUs * 100) : 0) + '%';

            return (
              <Row key={i} className={styles.legendContainer}>
                <Col>
                  <ButtonItem
                    label={displayName}
                    value={id}
                    onChange={handleFilterChange}
                    type={isRealTime && isFlightOpen ? "checkbox" : "radio"}
                    classname={styles.buttonItem}
                    labelClassName={styles.buttonItemLabel}
                    isChecked={isChecked}
                    idPrefix={`${idPrefix}-${id}`}
                    data-cy-id={`Flights-UsagePanel-${name.replace(/\W/g, '')}Input`}
                  />
                </Col>
                <Col className={className} />
                <Col data-cy-id={`Flights-UsagePanel-${name.replace(/\W/g, '')}Count`}>
                  {(statsDisplayMode === 'number') ? count : percent}
                </Col>
              </Row>
            )
          })
        }
      </>
      <Row className={styles.bluetoothContainer}>
        <Col>
          <ButtonItem
            label="Bluetooth"
            value="bluetooth"
            onChange={(e) => {
              const id = e.target.value;
              const localFilters = Object.assign({}, filters);
              localFilters[id] = !localFilters[id];
              filtersHandler(localFilters);
            }}
            type="checkbox"
            classname={classnames(styles.buttonItem, styles.bluetoothButtonItem)}
            labelClassName={styles.buttonItemLabel}
            isChecked={filters['bluetooth']}
            idPrefix={`${idPrefix}-bluetooth`}
            data-cy-id="Flights-UsagePanel-BluetoothInput"
          />
        </Col>
        <img alt="" src={iconHeadsetLight} className={styles.bluetoothIcon} />
        <Col data-cy-id="Flights-UsagePanel-BluetoothCount">
          {data['bluetooth']}
        </Col>
      </Row>
    </Col >
  );
}

UsageDetail.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  filtersHandler: PropTypes.func.isRequired,
  isRealTime: PropTypes.bool,
  realTimeHandler: PropTypes.func.isRequired,
  totalITUs: PropTypes.number.isRequired,
  isFlightOpen: PropTypes.bool,
}

export default UsageDetail;
