/* eslint-disable global-require, max-len, no-nested-ternary */

import {
  getRandomInt,
  getRandomNumber,
} from '@dfp/common';

import {
  AIRCRAFT_TYPES,
  AIRPORTS,
  FLIGHT_STATUS_IN_AIR,
  FLIGHT_STATUS_ON_GROUND,
} from 'constants';
import {
  Aircraft,
  AircraftHistoryItem,
  Flight,
  FlightHistoryItem,
} from 'models';

if (!Number.isFloat) {
  Number.isFloat = function (value) {
    return typeof value === 'number'
      && this.isFinite(value);
  };
}

export const AIRCRAFT = [];
export const FLIGHTS = [];
export const EVENTS = [];

const totalAircraft = 5;

const AIRCRAFT_TYPE_A321NEO = AIRCRAFT_TYPES.getEnumerationItem('1');
const AIRPORTS_SORTED = AIRPORTS.getSortedEnumerationItems();

const now = Date.now();
const initialAircraftCreate = now - 30 * 24 * 60 * 60 * 1000;
const eventInterval = 15 * 60 * 1000;
const flightIntervalMin = 45 * 60 * 1000;
const flightIntervalMax = 5 * 60 * 60 * 1000;

const flightLatMin = 23.474566943957043;
const flightLatMax = 55.17931957145047;
const flightLngMin = -132.54726046428365;
const flightLngMax = -53.976721881958944;

function getRandomEnum(ENUM_ARRAY) {
  return ENUM_ARRAY[Math.floor(Math.random() * ENUM_ARRAY.length)];
}

for (let x = 0; x < totalAircraft; x++) {
  const created = getRandomInt(initialAircraftCreate, now);
  const modified = now;
  const aircraft = new Aircraft({
    created,
    modified,
    aircraft_type: AIRCRAFT_TYPE_A321NEO,
    is_connected: getRandomInt(0, 1),
    tail: `x${getRandomInt(1000, 9999)}`,
  });

  // create aircraft collections
  const aircraftHistory = [];
  const aircraftFlights = [];

  // loop through event intervals
  let flight;
  let flightHistory;
  for (let y = created + eventInterval; y <= modified; y += eventInterval) {
    // generate an aircraft history item
    const healthScore = aircraft.health_score;
    const status = (healthScore >= 80) ? 1
      : (healthScore >= 50) ? 2
        : (healthScore > 0) ? 3
          : 4;
    aircraftHistory.push(new AircraftHistoryItem({
      created: y,
      health_score: healthScore,
      status,
    }));
    aircraft.history = aircraftHistory.slice(-1);

    // determine if new flight is required
    if (!flight) {
      const fcreated = y;
      // const opened = fcreated - getRandomInt(0, eventInterval);
      const opened = fcreated;
      // const departed = opened + eventInterval + getRandomInt(0, eventInterval);
      const departed = fcreated;
      const arrived = opened + getRandomInt(flightIntervalMin, flightIntervalMax);
      const fmodified = now;
      flight = new Flight({
        created: fcreated,
        modified: fmodified,
        aircraft_id: aircraft.id,
        arrival_timestamp: arrived,
        departure_airport: getRandomEnum(AIRPORTS_SORTED),
        departure_timestamp: departed,
        destination_airport: getRandomEnum(AIRPORTS_SORTED),
        number: getRandomInt(1000, 9999),
        open_timestamp: opened,
      });

      flightHistory = [new FlightHistoryItem({
        created: y,
        latitude: getRandomNumber(flightLatMin, flightLatMax),
        longitude: getRandomNumber(flightLngMin, flightLngMax),
        status: FLIGHT_STATUS_ON_GROUND,
        time_since_departure: 0,
        time_to_destination: Math.round((flight.arrival_timestamp - y) / 60 / 1000),
        weight_on_wheels: true,
      })];
    }

    // if flight hasn't arrived yet
    if (y < flight.arrival_timestamp) {
      flightHistory.push(new FlightHistoryItem({
        created: y,
        latitude: getRandomNumber(flightLatMin, flightLatMax),
        longitude: getRandomNumber(flightLngMin, flightLngMax),
        status: FLIGHT_STATUS_IN_AIR,
        time_since_departure: Math.round((y - flight.departure_timestamp) / 60 / 1000),
        time_to_destination: Math.round((flight.arrival_timestamp - y) / 60 / 1000),
        weight_on_wheels: false,
      }));

      // flight has arrived
    } else {
      flightHistory.push(new FlightHistoryItem({
        created: y,
        latitude: getRandomNumber(flightLatMin, flightLatMax),
        longitude: getRandomNumber(flightLngMin, flightLngMax),
        status: FLIGHT_STATUS_ON_GROUND,
        time_since_departure: Math.round((y - flight.departure_timestamp) / 60 / 1000),
        time_to_destination: 0,
        weight_on_wheels: true,
      }));
      flight.history = flightHistory;

      FLIGHTS.push(flight);
      aircraftFlights.push(flight);

      flight = null;
    }
  }

  aircraft.history = aircraftHistory;
  aircraft.flights = aircraftFlights;

  AIRCRAFT.push(aircraft);
}

AIRCRAFT.forEach((ac, index) => { AIRCRAFT[index] = ac.export(); });
FLIGHTS.forEach((ft, index) => { FLIGHTS[index] = ft.export(); });
EVENTS.forEach((ev, index) => { EVENTS[index] = ev.export(); });




// function generateAircraftHistory() {
//   const events = [];

//   const totalEvents = 7 * 24 * 4;  // total events in a 7 day period (24 hours at 4 events / hour);
//   const eventInterval = 15 * 60 * 1000 // event interval is 15 minutes
//   const startTime = Date.now();

//   for (let x = 0; x < totalEvents; x++) {
//     const time = startTime - (eventInterval * x);
//     const healthScore = getRandomNumber(0, 100);
//     const status = (healthScore >= 80) ? 1 : (healthScore >= 50) ? 2 : (healthScore > 0) ? 3 : 4;
//     const event = {
//       "id": totalEvents - x,
//       "created": time,
//       "al": getRandomNumber(1000, 39000),
//       "hs": healthScore,
//       "la": getRandomNumber(-90, 90),
//       "lo": getRandomNumber(-180, 180),
//       "st": status
//     };
//     events.unshift(event);
//   }

//   return events;
// }
