// From: https://loading.io/css/

import React from "react";
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const LoadingSpinner = (props) => {
  const { display } = props;


  return (
    <div className={styles.root}>
      {
        display === 'ellipsis' ?
          (
            <div className={styles.ellipsis}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className={styles.bar}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )
      }

    </div>
  )
}

LoadingSpinner.defaultProps = {
  display: 'bar'
};

LoadingSpinner.propTypes = {
  display: PropTypes.string
};

export default LoadingSpinner;
