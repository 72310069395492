import React from 'react';
import PropTypes from 'prop-types';
import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail'
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    className: styles.smallCol,
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru'
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos'
  }, {
    name: 'WAP OS',
    key: 'wap_os',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-WapOs'
  }, {
    name: 'WAP Config',
    key: 'wap_config',
    size: 'small',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-WapConfig'
  }, {
    name: 'SIB FW',
    key: 'sib_fw',
    size: 'small',
    className: styles.smallCol,
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-SibFw'
  }, {
    name: 'ITUs served primary',
    key: 'itus_primary',
    size: 'small',
    className: styles.smallCol,
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItusServedPrimary'
  }, {
    name: 'ITUs served secondary',
    key: 'itus_secondary',
    size: 'small',
    className: styles.smallCol,
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItusServedSecondary'
  }
];

// const faultCol = [
//   {
//     name: 'Fault',
//     key: 'fault',
//   }, {
//     name: 'Last flight',
//     key: 'last_flight',
//   }, {
//     name: '7 day average',
//     key: 'avg'
//   }];

const WAPs = (props) => {
  const { lruData, aircraft } = props;

  let itus_primary;
  let itus_secondary;
  if (aircraft) {
    const aircraftLopa = aircraft.lopa;
    const { waps } = aircraftLopa;
    itus_primary = waps[lruData.position - 1].primary_passenger_displays.length + waps[lruData.position - 1].primary_public_displays.length;
    itus_secondary = waps[lruData.position - 1].secondary_passenger_displays.length + waps[lruData.position - 1].secondary_public_displays.length;
  }

  const latestLRUHistory = lruData.getLatestHistory();
  let wap_config;
  let wap_os;
  let sib_fw;
  if (latestLRUHistory?.software_version) {
    const { software_version: { CFG, OSV, SFW } } = latestLRUHistory;
    wap_config = CFG;
    wap_os = OSV;
    sib_fw = SFW;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      wap_os: wap_os,
      wap_config: wap_config,
      sib_fw: sib_fw,
      itus_primary: itus_primary,
      itus_secondary: itus_secondary
    }
  ];

  // const faultData = [
  //   {
  //     fault: 'Disconnected ITU',
  //     last_flight: lruData.fault_count_last_flight,
  //     avg: lruData.fault_count_seven_day_average,
  //   }
  // ];

  return (
    <Row data-cy-id="LruDetailsOverlay-overviewContainer" className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col data-cy-id="LruDetailsOverlay-overviewTableTitle" className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={overviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
        {/* <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={faultCol}
              rows={faultData}
            />
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
}

WAPs.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
}

export default WAPs;
