import { TOGGLE_DEBUG } from 'actions';

export function debug(state = {}, action) {
  switch (action.type) {
    case TOGGLE_DEBUG:
      return !state;
    default:
      return state;
  }
}
