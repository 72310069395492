import React from "react";
import styles from './styles.module.css';
import { Col, Row } from 'react-bootstrap';

const UnAuthorized = () => {
  return (
    <Row className={styles.root} noGutters>
      <Col lg="auto">
        <Row>
          <Col className={styles.header}>
            Not authorized
          </Col>
        </Row>
        <Row>
          <Col className={styles.content}>
            You are not authorized to access this resource.
            If you require access, please contact the site administrators.
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default UnAuthorized;
