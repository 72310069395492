import {
  UPDATE_AIRCRAFT_LIST,
} from 'actions';

import { AIRCRAFT_STATUS_OFFLINE } from 'constants';
import { getFleetHealthHistogramData } from 'utils';

export function fleetHealth(state = [], action) {
  switch (action.type) {
    case UPDATE_AIRCRAFT_LIST:
      const { aircraftList } = action;
      if (aircraftList.length < 1) {
        return state;
      }

      // histogram = average fleet health at an interval over the last 7 days
      const histogramData = getFleetHealthHistogramData(
        aircraftList.map((aircraft) =>
          (aircraft.status.id !== AIRCRAFT_STATUS_OFFLINE.id) ? aircraft.history : []
        )
      );

      return histogramData;
    default:
      return state;
  }
}