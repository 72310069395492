import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Aircraft } from 'models';
import {
  EVENT_TYPE_RSSI,
  EVENT_TYPE_WAP_PRIMARY,
  EVENT_TYPE_WAP_SECONDARY,
  EVENT_TYPE_OFFLINE
} from 'constants';
import { sortAscendingByCreated } from 'utils';

import LOPA from 'components/LOPA';
import SkyPulseTable from 'components/SkyPulseTable';
import ButtonItem from 'components/inputs/ButtonItem';
import RSSIKeyImg from "assets/images/RSSIKey.png";
import LoadingSpinner from 'components/LoadingSpinner';

import { Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';

const idPrefix = 'heatMap';
const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Flights-WapTableColumn-Lru'
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'Flights-WapTableColumn-Pos'
  },
  {
    name: 'Average RSSI',
    key: 'avg_rssi',
    dataCyClassName: 'Flights-WapTableColumn-AverageRssi'
  },
  {
    name: 'ITUs expected',
    key: 'itus_expected',
    dataCyClassName: 'Flights-WapTableColumn-ItusExpected'
  },
  {
    name: 'ITUs actual',
    key: 'itus_actual',
    dataCyClassName: 'Flights-WapTableColumn-ItusActual'
  },
  {
    name: 'ITUs served',
    key: 'itus_served',
    size: 'small',
    dataCyClassName: 'Flights-WapTableColumn-ItusServed'
  },
];

const FlightHeatMap = (props) => {
  const { aircraft, push, flight } = props;
  const { aircraft_type: aircraftType, lopa: aircraftLopa } = aircraft;
  const { waps, passenger_displays: passengerDisplays } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);
  const [selectedLruId, setSelectedLruId] = useState(null);
  const [showRSSIOverlay, setShowRSSIOverlay] = useState(false);
  const [wapConnections, setWapConnections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const connectionMap = {};
    passengerDisplays.forEach((pd) => {
      let connectedToPrimaryWap = true;
      let connectedToSecondaryWap = false;
      let rssiValue = 0;
      flight.events.sort(sortAscendingByCreated)
        .filter((event) => (event.lru_position === pd.id && (
          event.event_type.id === EVENT_TYPE_WAP_PRIMARY.id
          || event.event_type.id === EVENT_TYPE_WAP_SECONDARY.id
          || event.event_type.id === EVENT_TYPE_OFFLINE.id
          || event.event_type.id === EVENT_TYPE_RSSI.id
        )))
        .map((event) => {
          if (rssiValue !== 0) return true;
          switch (event.event_type.id) {
            case EVENT_TYPE_OFFLINE.id:
              connectedToPrimaryWap = false;
              connectedToSecondaryWap = false;
              break;
            case EVENT_TYPE_WAP_SECONDARY.id:
              connectedToPrimaryWap = false;
              connectedToSecondaryWap = true;
              break;
            case EVENT_TYPE_WAP_PRIMARY.id:
              connectedToPrimaryWap = true;
              connectedToSecondaryWap = false
              break;
            case EVENT_TYPE_RSSI.id:
              rssiValue = event.extra.rssi;
              break;
            default:
              break;
          }
          connectionMap[pd.id] = {
            connectedToPrimaryWap: connectedToPrimaryWap,
            connectedToSecondaryWap: connectedToSecondaryWap,
            rssiValue: rssiValue,
          }
          return true;
        })
    })
    setWapConnections(connectionMap);
    setIsLoading(false);
  }, [flight, passengerDisplays]);

  const onRowClick = (lruId, buttonId = null) => {
    if (
      selectedLruId === lruId &&
      (buttonId === null || buttonId === activeButtonId)
    ) {
      setActiveButtonId(null);
      setSelectedLruId(null);
    } else {
      setActiveButtonId(buttonId);
      setSelectedLruId(lruId);
    }
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const lopaData = {
    passenger_displays: {}
  };

  passengerDisplays.forEach((pd) => {
    lopaData.passenger_displays[pd.id] = {
      selected: (!selectedLruId) ? true : false
    };
  });

  let allRSSITotal = 0;
  let totalITUs = 0;
  const data = waps.map(({
    id,
    primary_passenger_displays,
    secondary_passenger_displays,
    lru_type
  }) => {
    const selectedWap = selectedLruId === id;

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lru_type.id, id);

    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      'active': true,
      'selected': selectedWap,
    };
    let itusCount = 0;
    let rssiTotal = 0;

    // Assigning RSSI value to every seats.
    flight.events.sort(sortAscendingByCreated)
      .filter((event) => (
        event.event_type.id === EVENT_TYPE_RSSI.id
      ))
      .map((event) => {
        const rssiValue = event.extra.rssi;
        if (lopaData.passenger_displays[event.lru_position]) {
          lopaData.passenger_displays[event.lru_position]['heatmap'] = rssiValue;
        }
        return true;
      });

    // WAP based calculations
    primary_passenger_displays.forEach((pd) => {
      if (!wapConnections[pd]) {
        return;
      }
      const connectedToPrimaryWap = wapConnections[pd]['connectedToPrimaryWap'] || false;
      let rssiValue = wapConnections[pd]['rssiValue'] || 0;
      if (connectedToPrimaryWap) {
        itusCount++;
        rssiTotal += rssiValue;
      }
      if (selectedWap) {
        lopaData.passenger_displays[pd]['selected'] = connectedToPrimaryWap;
      }
      allRSSITotal += rssiValue;
    });

    secondary_passenger_displays.forEach((spd) => {
      if (!wapConnections[spd]) {
        return;
      }
      const connectedToSecondaryWap = wapConnections[spd]['connectedToSecondaryWap'] || false;
      let rssiValue = wapConnections[spd]['rssiValue'] || 0;
      if (connectedToSecondaryWap) {
        itusCount++;
        rssiTotal += rssiValue;
      }
      if (selectedWap) {
        lopaData.passenger_displays[spd]['selected'] = connectedToSecondaryWap;
      }
      allRSSITotal += rssiValue;
    });

    if (selectedWap && activeButtonId) {
      const pds = (activeButtonId === 'Primary') ? primary_passenger_displays : secondary_passenger_displays;
      pds.forEach((pd) => {
        lopaData.passenger_displays[pd]['associated'] = true;
      });
    }

    const buttons = ['Primary', 'Secondary'].map((display, i) => {
      return (
        <div
          key={i}
          className={classNames(
            "skypulsetable_toggle_button",
            (selectedWap && display === activeButtonId)
              ? "active"
              : null
          )}
          data-clickable-id={display}
        >
          {display}
        </div>
      )
    });

    totalITUs += itusCount;

    return (
      {
        id: id,
        itus_served: buttons,
        lru: lru_type.value,
        pos: id,
        avg_rssi: !rssiTotal ? 0 : (rssiTotal / itusCount).toFixed(1),
        itus_expected: primary_passenger_displays.length,
        itus_actual: itusCount,
      }
    );
  });
  const avgITURSSI = !allRSSITotal ? 0 : (allRSSITotal / totalITUs).toFixed(1);

  return (
    <div className={classNames(styles.flightWrapper, aircraftType.value.name)}>
      <LOPA
        mode="heatmap"
        data={lopaData}
        push={push}
        aircraft={aircraft}
        showRSSIValue={showRSSIOverlay}
      />
      <Row noGutters>
        <Col data-cy-id="Flights-HeatMapPanel-RssiKeyArea" className={styles.leftContainer}>
          <Row>
            <Col className={styles.rssiTitle}>
              RSSI
            </Col>
          </Row>
          <Row noGutters>
            <img alt="" src={RSSIKeyImg} className={styles.key} />
          </Row>
        </Col>
        <Col>
          <Row className={styles.infoContainer} noGutters>
            <Col data-cy-id="Flights-HeatMapPanel-RssiInfoArea" className={styles.info}>
              Average ITU RSSI ({totalITUs} ITU):&nbsp;
              <span className={styles.boldText}> {avgITURSSI} </span>
              <div className={styles.divider} />
              <div data-cy-id="Flights-HeatMapPanel-RssiOverlayContainer" className={styles.rssiOverlayContainer}>
                LOPA:
                <ButtonItem
                  label="RSSI value overlay"
                  value="rssi_overlay"
                  onChange={() => {
                    setShowRSSIOverlay(!showRSSIOverlay);
                  }}
                  type="checkbox"
                  classname={classNames(styles.buttonItem, styles.bluetoothButtonItem)}
                  isChecked={showRSSIOverlay}
                  idPrefix={`${idPrefix}-rssiOverlay`}
                  data-cy-id={`${idPrefix}-rssiOverlay`}
                />
              </div>
            </Col>
            <Col data-cy-id="Flights-HeatMapPanel-Note" md="auto" className={styles.note}>
              Note: First available values from this flight
              <br /> are shown for LOPA & WAP table.
            </Col>
          </Row>
          <Row className={styles.tableContainer} noGutters>
            <Col className="h-100">
              <SkyPulseTable
                title="WAP"
                columns={columns}
                rows={data}
                onRowClick={onRowClick}
                selectedId={selectedLruId}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

FlightHeatMap.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  flight: PropTypes.object,
  push: PropTypes.func,
}

export default FlightHeatMap;
