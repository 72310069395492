import React from 'react';
import PropTypes from 'prop-types';

import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import LOPA from 'components/LOPA';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from '../styles.module.css';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-ServersTableColumn-Lru'
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-ServersTableColumn-Pos'
  },
  {
    name: 'SW part name',
    key: 'sw_part_name',
    size: 'medium',
    dataCyClassName: 'Ship-ServersTableColumn-SwPartName'
  },
  {
    name: 'SW part number',
    key: 'sw_part_number',
    size: 'medium',
    dataCyClassName: 'Ship-ServersTableColumn-SwPartNumber'
  }
];

const Servers = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { servers } = aircraftLopa;

  const lopaData = {};
  const tableDataMap = {};

  servers.forEach(({ id: srvId, lru_type: lruType }) => {

    // Get corresponding LRU
    const lru = aircraft.getLRUByTypePosition(lruType.value, srvId);
    const typeName = lruType.value;

    if (!tableDataMap[typeName]) {
      tableDataMap[typeName] = [];
    }

    // Table Data
    if (lru?.software_version?.MNF) {
      const { lru_type, position: pos, software_version: { MNF } } = lru;
      tableDataMap[typeName].push(...MNF.map(({ PN: sw_part_number, NM: sw_part_name }) => {
        return (
          {
            id: srvId,
            lru: lru_type.value,
            pos,
            sw_part_name,
            sw_part_number
          }
        );
      }))
    } else {
      tableDataMap[typeName].push(
        {
          lru: lruType.value,
          id: srvId,
          pos: srvId
        });
    }
    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, srvId);

    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][srvId] = {
      'active': true,
      'selected': selectedLru === srvId,
    };
  });

  const tableMaxHeight = `calc(100%/${Object.keys(tableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            {
              Object.keys(tableDataMap).map((key, index) => {
                const tData = tableDataMap[key];
                return (
                  <Row
                    key={index}
                    className={styles.multiTable}
                    style={{ maxHeight: tableMaxHeight }}
                    noGutters
                  >
                    <Col className="h-100">
                      <SkyPulseTable
                        title={`Server (${key === 'SRV' ? 'W-IFE' : key}) manifest`}
                        columns={columns}
                        rows={tData}
                        onRowClick={onRowClick}
                      />
                    </Col>
                  </Row>
                );
              })
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Servers.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
}

export default Servers;
