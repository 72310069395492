import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { AIRCRAFT_STATUS } from 'constants';

import {
  Col,
  Image,
  Row
} from 'react-bootstrap';
import HealthFilterOverlay from 'components/overlays/HealthFilterOverlay';

import styles from './styles.module.css';

// Icons
import Blank from 'assets/icons/Blank.png';
import Degraded from 'assets/icons/Degraded_1.png';
import Offline from 'assets/icons/Offline_1.png';
import Operational from 'assets/icons/Operational_1.png';
import Unhealthy from 'assets/icons/Unhealthy_1.png';
const icons = { 'Degraded': Degraded, 'Offline': Offline, 'Operational': Operational, 'Unhealthy': Unhealthy };

const HealthFilterPanel = (props) => {
  const {
    aircraftCount,
    currentFilters,
    onDone
  } = props;
  const [overlayPosition, setOverlayPosition] = useState(null);

  // Used to position the overlay
  const legend = useRef();

  const onOpenOverlay = () => {
    const { left, top } = legend.current.getBoundingClientRect();
    setOverlayPosition({ x: left, y: top });
  }

  const onOverlayDone = async (localStatuses) => {
    const selected = Object.keys(localStatuses).filter((key) => localStatuses[key]);
    setOverlayPosition(null);
    onDone(selected);
  }

  const aircraftStatusSelected = currentFilters.aircraft_status
    ? currentFilters.aircraft_status
    : [];

  const selectedStatusesMap = {};
  AIRCRAFT_STATUS.getSortedEnumerationItems().forEach((statusItem) => {
    const { id } = statusItem;
    selectedStatusesMap[id] = aircraftStatusSelected.find((status) => status === id) ? true : false;
  });

  return (
    <>
      <Row className={styles.root} data-cy-id="Dashboard-HealthFiltersPanel" noGutters>
        <Col data-cy-id="Dashboard-HealthFiltersPanel-Title" md="auto" className="title_3">
          {aircraftCount} aircraft total
        </Col>
        <Col md="auto">
          <Row noGutters>
            <Col md="auto" className={classnames("label_1", styles.healthFilterButtonLabel)}>
              Show:
            </Col>
            <Col
              md="auto"
              ref={legend}
              className={classnames('button_reg_blank', styles.legendContainer)}
              data-cy-id="Dashboard-HealthFiltersPanel-FilterButton"
              onClick={onOpenOverlay}
            >
              {
                AIRCRAFT_STATUS.getSortedEnumerationItems().map((enumItem, index) => {
                  const { id, value: { name } } = enumItem;
                  const src = aircraftStatusSelected.find((statusId) => statusId === id)
                    ? icons[name]
                    : Blank;
                  return (
                    <Image key={index} className={styles.icon} src={src} alt={""} data-cy-id={`Dashboard-HealthFilterStatusIndicator-${name}`} />
                  );
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <HealthFilterOverlay
        selectedStatuses={selectedStatusesMap}
        position={overlayPosition}
        isOpen={(overlayPosition) ? true : false}
        onCancel={() => onOverlayDone(selectedStatusesMap)}
        onDone={onOverlayDone}
      />
    </>
  );
}

HealthFilterPanel.propTypes = {
  aircraftCount: PropTypes.number.isRequired,
  currentFilters: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default HealthFilterPanel;
