import React, { useState } from 'react';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
} from 'constants';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';
import { ReportFactory } from 'classes';

import styles from './styles.module.css';

const idPrefix = 'ConfigurableFeaturesReport';
const ConfigurableFeatures = () => {
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [reportOverlayOpen, setReportOverlayOpen] = useState(false);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col data-cy-id={`${idPrefix}-ScreenHeader`} className="title_1"> Configurable features report </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id="ConfigurableFeatures-Report-Filters" noGutters>
            <Col>
              <Row className={styles.filterContainer}>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption.value}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setFleetOption(value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id="ConfigurableFeatures-Report-CreateReportButton"
                onClick={() => setReportOverlayOpen(true)}
              >
                Create report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="Configurable features report"
        data={<>
          <Row>
            <Col> Fleet </Col>
            <Col>
              {fleetOption === '' ? 'All' : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name}
            </Col>
          </Row>
        </>}
        isOpen={reportOverlayOpen}
        loading={messageOverlayOpen}
        onCancel={() => setReportOverlayOpen(false)}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getConfigurableFeaturesReport(fleetOption === '' ? 'All' : fleetOption);
          setMessageOverlayOpen(false);
          setReportOverlayOpen(false);
        }}
      />
    </>
  );
}

export default ConfigurableFeatures;
