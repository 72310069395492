import React from 'react';
import PropTypes from 'prop-types';

import { IATA_UNKNOWN } from 'constants';
import { Aircraft } from 'models';
import { convertMinutesToTime } from 'utils';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'
import { FlightIcon } from 'components/SkypulseIcons';

import styles from './styles.module.css';

const FlightTracker = (props) => {
  let { aircraft, flight: propsFlight } = props;
  let isConnected = aircraft.is_connected || false;
  let depIATA = IATA_UNKNOWN;
  let desIATA = IATA_UNKNOWN;
  let isOpen = false;
  let progressPercent = 0;
  let tft = 0;
  let tsd = 0;
  let ttd = 0;

  const flight = (propsFlight) ? propsFlight : aircraft.getCurrentFlight();
  if (flight) {
    if (flight.departure_airport) {
      depIATA = flight.departure_airport.value.iata;
    }

    if (flight.destination_airport) {
      desIATA = flight.destination_airport.value.iata;
    }
    isOpen = flight.is_open;
    tsd = flight.time_since_departure || 0;
    ttd = flight.time_to_destination || 0;
    tft = tsd + ttd;

    progressPercent = Math.round(tsd / tft * 100);
    if (isNaN(progressPercent)) {
      progressPercent = 0;
    }
    if (progressPercent > 100) {
      progressPercent = 100;
    }
  }

  return (
    <Container data-cy-id="FlightTracker-Container" className={styles.flightTracker}>
      <Row>
        <Col>
          {!isConnected
            ? <FlightIcon fill="white" rotate="90" height="20" width="20" />
            : null
          }
          {(isConnected && !isOpen)
            ? <>
              <FlightIcon fill="white" rotate="90" height="20" width="20" />
              <br />
              <strong>{desIATA}</strong>
            </>
            : null
          }
          {
            !(isConnected && isOpen)
              ?
              null
              : (
                <>
                  <div className={styles.bar}>
                    <span data-cy-id="FlightTracker-DepartAirport" className={styles.depart}>
                      <span>{depIATA}</span>
                    </span>
                    <div data-cy-id="FlightTracker-FlightProgress" className={styles.progress} style={{ width: `${progressPercent}%` }}>
                      <span data-cy-id="FlightTracker-TimeInAir" className={styles.timeInAir}>{convertMinutesToTime(tsd)}</span>
                      <div className={styles.icon}>
                        <FlightIcon fill="white" rotate="90" height="25" width="25" />
                      </div>
                    </div>
                    <div data-cy-id="FlightTracker-FlightRemaining" className={styles.remaining} style={{ width: `${100 - progressPercent}%` }}>
                      <span data-cy-id="FlightTracker-TimeRemaining" className={styles.estimatedTimeRemaining}>{convertMinutesToTime(ttd)}</span>
                    </div>
                    <span data-cy-id="FlightTracker-ArriveAirport" className={styles.arrive}>
                      <span>{desIATA}</span>
                    </span>
                  </div>
                </>
              )}
        </Col>
      </Row>
    </Container>
  );
}

FlightTracker.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
}

export default FlightTracker;
