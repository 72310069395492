import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
} from 'constants';
import { ReportFactory } from 'classes';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';

import styles from './styles.module.css';

const idPrefix = 'LRUFaultsReport';
const LRUFaults = () => {

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col data-cy-id={`${idPrefix}-ScreenHeader`} className="title_1"> LRUs & Faults report </Col>
            <Col md="auto" className={styles.note}>
              Note: Data is available for previous 30 days.
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id={`${idPrefix}-Filters`} noGutters>
            <Col>
              <Row>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption}
                    onChange={(e) => {
                      const { target: { value } } = e
                      setFleetOption(value)
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-CreateReportButton`}
                onClick={() => setOverlayOpen(true)}
              >
                Create report
            </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="LRUs & Faults report"
        data={<>
          <Row>
            <Col> Fleet: </Col>
            <Col> {fleetOption === '' ? 'All' : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name} </Col>
          </Row>
        </>}
        isOpen={overlayOpen}
        onCancel={() => setOverlayOpen(false)}
        loading={messageOverlayOpen}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getLruReport(fleetOption === '' ? 'All' : fleetOption);
          setMessageOverlayOpen(false);
          setOverlayOpen(false);
        }}
      />
    </>
  );
}

export default LRUFaults;
