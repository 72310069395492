import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { setActiveAircraft } from 'actions';
import {
  PATHS,
  LRU_TYPES,
  LruTypeIgnoreList
} from 'constants';

import {
  Col,
  Row,
} from 'react-bootstrap';
import LeftNav from 'components/default/LeftNav';
import Content from './Content';
import Overview from './Overview';
import Software from './Software';
import Features from './Features';
import Health from './Health';

import styles from './styles.module.css';

const LRUFaults = (props) => {
  const {
    push,
    rAircraftList,
    setActiveAircraft,
    match: { params },
  } = props;

  const activeMenuItem = params && params.page ? params.page : 'overview';

  const healthMenu = [];
  LRU_TYPES.getSortedEnumerationItems()
    .filter((type) => !LruTypeIgnoreList.includes(type.value))
    .forEach((type) => {
      const { id, value } = type;
      healthMenu.push({
        display: value,
        id: id,
      });
    });
  healthMenu.sort((a, b) => a.display.localeCompare(b.display));

  const NavMap = {
    'Fleet health': [
      {
        display: 'Overview',
        id: 'overview',
      },
      {
        display: 'Features',
        id: 'features',
      },
      {
        display: 'Content',
        id: 'content',
      },
      {
        display: 'Software',
        id: 'software',
      },
    ],
    'LRU health': healthMenu
  };

  const renderMenuItem = () => {
    if (activeMenuItem > 0 && activeMenuItem < 23) {
      return renderLruHealthPage();
    }

    switch (activeMenuItem) {
      case 'content':
        return <Content aircraftList={rAircraftList} onFleetRowClick={onFleetRowClick} push={push} />
      case 'software':
        return <Software aircraftList={rAircraftList} onFleetRowClick={onFleetRowClick} push={push} />
      case 'features':
        return <Features aircraftList={rAircraftList} onFleetRowClick={onFleetRowClick} push={push} />
      case 'overview':
      default:
        return <Overview aircraftList={rAircraftList} onFleetRowClick={onFleetRowClick} push={push} />
    }
  }

  const onFleetRowClick = (id) => {
    const aircraft = rAircraftList.find((ac) => ac.id === id);
    setActiveAircraft(aircraft);
    push(PATHS.ship);
  }

  const onTailClick = (tail) => {
    const aircraft = rAircraftList.find((ac) => ac.tail === tail);
    setActiveAircraft(aircraft);
    push(PATHS.ship);
  }

  const renderLruHealthPage = () => {
    return <Health
      lruTypeId={activeMenuItem}
      aircraftList={rAircraftList}
      push={push}
      onTailClick={onTailClick}
    />;
  }

  return (
    <Row className={styles.root} noGutters>
      <Col md="auto" className={styles.leftPanel}>
        <LeftNav
          activeMenuItem={activeMenuItem}
          changeActiveMenu={(id) => push(`${PATHS.lrus}/${id}`)}
          navMap={NavMap}
          primaryHeader="LRUs & Faults"
          testIdPrefix="LRUFaults"
        />
      </Col>
      <Col className={styles.container}>
        {renderMenuItem()}
      </Col >
    </Row >
  );
}

const state = (state) => {
  return ({
    rAircraftList: state.aircraftList,
  });
};

const actions = {
  push,
  setActiveAircraft,
};

export default connect(state, actions)(LRUFaults);
