import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { AIRCRAFT_STATUS } from 'constants';

import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';

import styles from './styles.module.css';

const HealthFilterOverlay = (props) => {
  const { selectedStatuses, isOpen, onCancel, onDone, position } = props;
  const [localStatuses, setLocalStatuses] = useState(selectedStatuses);

  useEffect(() => {
    setLocalStatuses(selectedStatuses);
  }, [selectedStatuses]);

  const onCheckChange = (key, checked) => {
    const local = Object.assign({}, localStatuses);
    local[key] = checked;
    setLocalStatuses(local);
  }

  const onDoneClicked = () => {
    onDone(localStatuses);
  }

  const showAll = () => {
    let selectedMap = {}
    AIRCRAFT_STATUS.getSortedEnumerationItems().forEach((status) => {
      selectedMap[status.id] = true;
    });
    setLocalStatuses(selectedMap);
    onDone(selectedMap);
  }

  const Footer = (props) => {
    const { doneDisabled } = props;
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto"><Button className="button_reg" data-cy-id="Dashboard-HealthFiltersOverlay-ShowAllButton" onClick={showAll}>Show All</Button></Col>
        <Col lg="auto"><Button className="button_call" data-cy-id="Dashboard-HealthFiltersOverlay-DoneButton" onClick={onDoneClicked} disabled={doneDisabled}>Done</Button></Col>
      </Row>
    )
  }

  const HealthSelectionBar = (props) => {
    const { item } = props;

    const { id, value: { name: label } } = item;
    const isChecked = localStatuses[id] ? true : false;
    const selectedClassname = isChecked ? styles.selected : styles.unselected;
    const selectedColorStyle = isChecked ? `var(--CL_STATUS_${label.toUpperCase()}` : null;
    const fieldText = isChecked ? 'field_5' : 'field_6';
    return (
      <div
        className={classnames(styles.healthSelectionBar, selectedClassname)}
        data-cy-id={`Dashboard-HealthFiltersOverlay-${label}Button`}
        style={{ backgroundColor: selectedColorStyle }}
        onClick={() => onCheckChange(id, !isChecked)}
      >
        <input type="checkbox" checked={isChecked} className={styles.checkbox} readOnly />
        <div className={styles.statusContainer}>
          <label className={classnames(styles.status, fieldText)}>{label}</label>
        </div>
      </div>
    );
  }

  // Initial aircraftsearch is null
  if (!localStatuses) {
    return null;
  }

  const doneDisabled = Object.values(localStatuses).every((enabled) => enabled === false);

  return (
    <Overlay
      data-cy-id="Dashboard-HealthFiltersOverlay"
      title="Show:"
      isOpen={isOpen}
      position={position}
      footer={<Footer doneDisabled={doneDisabled} />}
      onCancel={() => {
        setLocalStatuses(selectedStatuses);
        onCancel();
      }}
    >
      <Row className={styles.root} noGutters>
        <Col lg="auto">
          {
            AIRCRAFT_STATUS.getSortedEnumerationItems().map((enumItem, i) => {
              return (
                <HealthSelectionBar key={i} item={enumItem} />
              );
            })
          }
        </Col>
      </Row>
    </Overlay >
  );
}

HealthFilterOverlay.propTypes = {
  selectedStatuses: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  position: PropTypes.object
};

export default HealthFilterOverlay;
