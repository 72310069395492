import { DFPAPILambdaJSONResponse, DFPAPILambdaJSONErrorResponse, DFPAPILambdaError } from 'classes';
import { AIRCRAFT } from './data';

export function getAircraft(data = {}) {
  const { queryStringParameters: { aid, tail } } = data;

  let aircraftData;
  if (aid) {
    aircraftData = AIRCRAFT.find((ac) => ac.id === aid);
  } else if (tail) {
    aircraftData = AIRCRAFT.find((ac) => ac.tail === tail);
  }

  if (aircraftData) {
    return new DFPAPILambdaJSONResponse(aircraftData);
  }

  return new DFPAPILambdaJSONErrorResponse(new DFPAPILambdaError('Aircraft not found', 404));
}

export function postAircrafts(data = {}) {
  const { body, queryStringParameters: { all } } = data;
  const { aids, tails } = (body) ? body : {};

  let aircraftData;
  if (all) {
    aircraftData = [...AIRCRAFT];
  } else if (aids) {
    aircraftData = AIRCRAFT.filter((ac) => aids.find((aid) => ac.id === aid));
  } else if (tails) {
    aircraftData = AIRCRAFT.filter((ac) => tails.find((tail) => ac.tl === tail));
  }

  if (aircraftData && Array.isArray(aircraftData) && aircraftData.length > 0) {
    return new DFPAPILambdaJSONResponse({ aircraft: aircraftData });
  }

  return new DFPAPILambdaJSONErrorResponse(new DFPAPILambdaError('Aircraft not found', 404));
}
