import {
  FLIGHT_STATUS_UNKONWN,
  IATA_UNKNOWN,
} from 'constants';

import {
  getUTCShortDate,
} from 'utils';

export function getFlightHeaderText(flight) {
  let headerText = 'No flight available';
  if (flight) {
    const depIATA = flight.departure_airport?.value.iata ?? IATA_UNKNOWN;
    const desIATA = flight.destination_airport?.value.iata ?? IATA_UNKNOWN;
    const flightStatusName = flight.status?.value.name ?? FLIGHT_STATUS_UNKONWN;
    const dt = !flight.departure_timestamp ? null : getUTCShortDate(flight.departure_timestamp);
    headerText = `Flight ${flight.number}
    | ${flight.is_open ? flightStatusName : 'Closed'}
    | ${depIATA}-${desIATA}
    ${flight.is_open ? '' : !dt ? '' : `| ${dt} UTC`
      } `;
  }

  return headerText;
}
