import React from 'react';
import { useAuth } from './useAuth';
export function withAuth(Component) {
  const displayName = `withAuth(${Component.displayName || Component.name})`;
  const C = (props) => {
    const auth = useAuth();
    return <Component {...props} {...auth} />;
  }
  C.displayName = displayName;
  return C;
}
