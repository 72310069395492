import { getRandomInt } from 'utils';

const mockMinTimeout = 100;
const mockMaxTimeout = 2000;
const mockErrorFrequency = 0.005;

const mockGETOptions = {
  headers: {},
  queryStringParameters: {},
};

const mockPOSTOptions = {
  body: {},
  headers: {},
};

const mockDELETEOptions = {
  headers: {},
};

// This is a cheesy mock request handler for testing and offline mode
export class MockAPIRequestHandler {
  static mocks = {};

  static getMockDataFromRequest(method, url, options, error = false) {

    const functionPath = url
      .split('/')
      .map((part) => {
        return part.capitalize();
      })
      .join('');

    // create data collection to pass to mock function

    const mockName = `${method}${functionPath}${error ? 'Error' : ''}`;
    const mockData = (() => {
      if (typeof this.mocks[mockName] === 'function') {
        return this.mocks[mockName](options);
      }
      console.log(`undefined mock ${mockName} - using default`);
      return (!error) ? this.mocks.defaultMock(options) : this.mocks.defaultMockError(options);
    })();
    return mockData;
  }

  // standard request handler for MockAPIRequestHandler
  static request(apiName, method, path, options) {
    return new Promise((resolve) => {
      // randomize success and execution time
      const succeed = Math.random() > mockErrorFrequency;
      const timeout = getRandomInt(mockMinTimeout, mockMaxTimeout);

      // await execution time
      setTimeout(() => {
        let mockResponse;
        if (succeed) {
          try {
            mockResponse = MockAPIRequestHandler.getMockDataFromRequest(method, path, options);
          } catch (e) {
            mockResponse = MockAPIRequestHandler.getMockDataFromRequest(method, path, e, true);
          }
        }

        if (!mockResponse) {
          // simulated failure path
          console.warn('Simulating error condition for request');
          mockResponse = MockAPIRequestHandler.getMockDataFromPath(method, path, new Error('Simulated error -- ignore'), true);
        }
        resolve({
          config: { path },
          data: JSON.parse(mockResponse.body),
          headers: { 'x-api-version': 'mock' },
        });
      }, timeout);
    });
  }

  static get(apiName, path, options = mockGETOptions) {
    return MockAPIRequestHandler.request(apiName, 'get', path, options);
  }

  static post(apiName, path, options = mockPOSTOptions) {
    return MockAPIRequestHandler.request(apiName, 'post', path, options);
  }

  static put(apiName, path, options = mockPOSTOptions) {
    return MockAPIRequestHandler.request(apiName, 'put', path, options);
  }

  static delete(apiName, path, options = mockDELETEOptions) {
    return MockAPIRequestHandler.request(apiName, 'delete', path, options);
  }

  static head(apiName, path, options = mockDELETEOptions) {
    return MockAPIRequestHandler.request(apiName, 'head', path, options);
  }
}
