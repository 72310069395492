import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'react-bootstrap';
import styles from './styles.module.css';

const Footer = (props) => {
  const { message } = props;

  return (
    <Row className={styles.container} data-cy-id="Global-Footer" noGutters>
      <Col md="auto" className={styles.message}>
        {message}
      </Col>
    </Row>
  );
};

Footer.propTypes = {
  message: PropTypes.string,
};

export default Footer
