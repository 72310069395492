import {
  AIRCRAFT_TYPES,
  CONNECTION_STATUS,
  EVENT_TYPES,
  FLIGHT_STATUS,
} from '@skypulse/common';

import {
  COMMON_LOG_LEVELS,
} from '@dfp/common';

export const PATHS = {
  home: '/',
  configurationHistory: '/configuration-history',
  content: '/content',
  dashboard: '/dashboard',
  lrus: '/lrus',
  flights: '/flights',
  flightsOverview: '/flights/overview',
  flightsUsage: '/flights/usage',
  flightsHeatmap: '/flights/heatmap',
  ship: '/ship',
  mapTest: '/mapTest',
  reports: '/reports',
  reportShip: '/reports/ship',
  reportFlight: '/reports/flight',
  reportLruFaults: '/reports/lrusFaults',
  reportLruDetail: '/reports/lruDetails',
  reportUsage: '/reports/usage',
  reportAvailability: '/reports/availability',
  reportConfigurableFeatures: '/reports/configurableFeatures',
  maintenance: '/maintenance',
};

export const ADMIN_PATHS = {
  home: '/dfp-admin.html',
  aircraft: '/aircraft',
  aircraft_detail: '/aircraft/detail',
  configuration: '/configuration',
  consolidatedOffloads: '/consolidatedOffloads',
};

export const KIOSK_PATHS = {
  home: '/kiosk.html',
  dashboard: '/dashboard',
  lrus: '/lrus',
  lruDetail: '/lruDetail',
  aircraft: '/aircraft',
  aircraftDetail: '/aircraftDetail',
}

export const TOTAL_TRANSMISSIONS_7_DAY = 7 * 24 * 4;

export const NOT_APPLICABLE = '--';
export const NOT_AVAILABLE = 'NA';
export const AIRCRAFT_STATUS_UNKNOWN = 'Unknown';
export const FLIGHT_STATUS_UNKONWN = 'Unknown';
export const IATA_UNKNOWN = 'UKN';
export const SYSTEM = 'System';

export const SELECT_OPTION_ALL = { name: 'All', value: '' };
export const DEFAULT_OPTION = { name: '--', value: '' };

// LOPA body type
export const LOPA_BODY_TYPE_NARROW = 'NARROW-BODY';
export const LOPA_BODY_TYPE_WIDE = 'WIDE-BODY';

export {
  COMMON_LOG_LEVELS,
} from '@dfp/common'

export {
  AIRCRAFT_CONFIGURATION,
  AIRCRAFT_CONFIGURATION_TYPE_BLUETOOTH_ZONES,
  AIRCRAFT_CONFIGURATION_TYPE_DISABLED_USB,
  AIRCRAFT_CONFIGURATION_TYPE_DISMISSIBLE_BILLBOARDS,
  AIRCRAFT_STATUS,
  AIRCRAFT_STATUS_DEGRADED,
  AIRCRAFT_STATUS_OFFLINE,
  AIRCRAFT_STATUS_OPERATIONAL,
  AIRCRAFT_STATUS_UNHEALTHY,
  AIRCRAFT_TYPES,
  AIRCRAFT_TYPE_A220100,
  AIRCRAFT_TYPE_A220300,
  AIRCRAFT_TYPE_A321NEO,
  AIRCRAFT_TYPE_A330941,
  AIRCRAFT_TYPE_B767400,
  AIRPORTS,
  CMP_TYPES,
  CMP_TYPE_ITU,
  CONNECTION_STATUS,
  DEVICE_COMPONENTS,
  EVENT_TYPES,
  EVENT_TYPE_BLUETOOTH_CONNECTED,
  EVENT_TYPE_BLUETOOTH_DISCONNECTED,
  EVENT_TYPE_CONTENT_UNAVAILABLE,
  EVENT_TYPE_CRASH_RESET,
  EVENT_TYPE_DATA_OFFLOAD,
  EVENT_TYPE_DATA_OFFLOAD_MISSED,
  EVENT_TYPE_DEVICE_ERROR,
  EVENT_TYPE_FLIGHT_CLOSE,
  EVENT_TYPE_FLIGHT_OPEN,
  EVENT_TYPE_FLIGHT_PHASE_LANDING,
  EVENT_TYPE_FLIGHT_PHASE_TAKEOFF,
  EVENT_TYPE_HARD_RESET,
  EVENT_TYPE_OFFLINE,
  EVENT_TYPE_PAX_USAGE,
  EVENT_TYPE_RECONNECT,
  EVENT_TYPE_RSSI,
  EVENT_TYPE_SERVICE_AVAILABLE,
  EVENT_TYPE_SERVICE_UNAVAILABLE,
  EVENT_TYPE_SOFT_RESET,
  EVENT_TYPE_WAP_PRIMARY,
  EVENT_TYPE_WAP_SECONDARY,
  FLIGHT_STATUS,
  FLIGHT_STATUS_IN_AIR,
  FLIGHT_STATUS_ON_GROUND,
  LRU_TYPES,
  LRU_TYPE_CMP,
  LRU_TYPE_ITU,
  LRU_TYPE_ITU_10,
  LRU_TYPE_ITU_13,
  LRU_TYPE_ITU_18,
  LRU_TYPE_MCU,
  LRU_TYPE_OVD,
  LRU_TYPE_PCU,
  LRU_TYPE_PD,
  LRU_TYPE_SRV,
  LRU_TYPE_SSDCL,
  LRU_TYPE_WAP,
  OFFLOAD_STATUS,
  PAYLOAD_CODES,
  PAX_USAGE_TYPES,
  PAX_USAGE_TYPE_IDLE,
  PCU_TYPES,
  PCU_TYPE_12_BUTTON,
  PERMISSIONS,
  RESOURCES,
  ROLES,
  ROLE_ADMINISTRATOR,
  ROLE_MAINTENANCE,
  ROLE_MARKETING,
  ROLE_USER,
  SCOPES,
  SERVICES,
  USER_STATUS,
  USER_STATUS_ACTIVE,
  USER_STATUS_NEW,
} from '@skypulse/common';

export const AIRPORT_UNKONWN = {
  id: '',
  value: {
    iata: IATA_UNKNOWN,
  },
};

export const AIRCRAFT_TYPE_OPTIONS_W_DEFAULT = [DEFAULT_OPTION]
  .concat(AIRCRAFT_TYPES.getSortedEnumerationItems().map((item) => {
    const { id } = item;
    return { name: AIRCRAFT_TYPES[id].name, value: id };
  }));

export const AIRCRAFT_TYPE_OPTIONS_W_ALL = [SELECT_OPTION_ALL]
  .concat(AIRCRAFT_TYPES.getSortedEnumerationItems().map((item) => {
    const { id } = item;
    return { name: AIRCRAFT_TYPES[id].name, value: id };
  }));

export const CONNECTION_STATUS_CONNECTED = CONNECTION_STATUS.getEnumerationItem('Connected');
export const CONNECTION_STATUS_OPTIONS = [SELECT_OPTION_ALL]
  .concat(CONNECTION_STATUS.getSortedEnumerationItems().map((item) => {
    const { id } = item;
    return { name: CONNECTION_STATUS[id], value: id };
  }));

export const EVENT_TYPE_OPTIONS = [SELECT_OPTION_ALL]
  .concat(EVENT_TYPES.getSortedEnumerationItems().map((item) => {
    const { id } = item;
    return { name: EVENT_TYPES[id].name, value: id };
  }));

export const FILTER_EVENT_TYPE_OPTIONS = EVENT_TYPES.getSortedEnumerationItems().map((item) => {
  const { id } = item;
  return { name: EVENT_TYPES[id].name, value: id };
}).sort((a, b) => a.name.localeCompare(b.name));

export const FAULT_TYPE_OPTIONS = [SELECT_OPTION_ALL]
  .concat(EVENT_TYPES.getSortedEnumerationItems()
    .filter((item) => EVENT_TYPES[item.id].is_fault)
    .map((item) => {
      const { id } = item;
      return { name: EVENT_TYPES[id].name, value: id };
    }));

export const FLIGHT_STATUS_OPTIONS = [SELECT_OPTION_ALL]
  .concat(FLIGHT_STATUS.getSortedEnumerationItems().map((item) => {
    const { id } = item;
    return { name: FLIGHT_STATUS[id].name, value: id };
  }));

export const DEV_USER = {
  auth_time: Date.now(),
  dlmid: '',
  family_name: "User",
  given_name: "Dev",
  jti: "0000000000000000000000",
  name: "User, Dev",
  'pi.sri': "000000000000000_000000000_0..0000",
  ppr: "000000000",
  preferred_username: "000000",
  roles: ['1', '2', '3', '4'],
  station: "XXX",
  sub: "000000",
};

export const DEFAULT_MAP_CENTERPOINT = {
  lat: 33.642125327374366,
  lng: -84.42801862818648,
}

export const DEFAULT_API_REQUEST_PAGE_SIZE = 100;
export const FLIGHT_GET_API_REQUEST_PAGE_SIZE = 200;

export const LOG_LEVEL_OPTIONS = COMMON_LOG_LEVELS.getSortedEnumerationItems().map((item) => {
  const { id } = item;
  return { name: COMMON_LOG_LEVELS[id], value: id };
});

export const LruTypeIgnoreList = ['CMP', 'CMS', 'ITU', 'MCU', 'PCU', 'WAP'];
