import React from 'react';
import BarGraph from 'components/BarGraph';
import { AIRCRAFT_STATUS, AIRCRAFT_STATUS_OFFLINE } from 'constants';
import { getHealthColorFromAircraftStatus } from 'utils';
import {
  Col,
} from 'react-bootstrap';
import styles from './styles.module.css';

const FleetHealthOverview = (props) => {
  const {
    aircraftList
  } = props;

  const statusMap = {};
  const AIRCRAFT_STATUS_OFFLINE_NAME = AIRCRAFT_STATUS_OFFLINE.value.name;

  AIRCRAFT_STATUS.getSortedEnumerationItems().forEach((statusItem) => {
    statusMap[statusItem.value.name] = 0;
  });

  aircraftList.forEach((aircraft) => {
    const aircraftStatusName = (aircraft.status) ? aircraft.status.value.name : null;
    if (aircraftStatusName) {
      statusMap[aircraftStatusName]++;
    } else {
      statusMap[AIRCRAFT_STATUS_OFFLINE_NAME]++;
    }
  });

  const barData = Object.entries(statusMap).map(([aircraftStatusName, count]) => {
    return {
      name: aircraftStatusName,
      value: count,
      total: aircraftList.length,
      fill: getHealthColorFromAircraftStatus(aircraftStatusName),
    };
  });

  return (
    <Col className={styles.root}>
      {barData?.map((status, index) => {
        const primaryFillPercent = status.total ? status.value * 100 / status.total : 0;
        return (
          <BarGraph
            key={index}
            title={status.name}
            primaryFillColor={status.fill}
            primaryFillPercent={primaryFillPercent}
            primaryLabel={status.value.toString()}
          />
        );
      })}
    </Col>
  );
};

export default FleetHealthOverview;
