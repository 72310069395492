import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  DEFAULT_OPTION,
  SELECT_OPTION_ALL
} from 'constants';
import { AircraftFactory, ReportFactory } from 'classes';
import { getUTCShortDate } from 'utils';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';

import styles from './styles.module.css';

const idPrefix = 'FlightReport';
const Flight = (props) => {

  const {
    aircraftList,
    flightList,
    flightId
  } = props;

  const flight = !flightId ? null : flightList?.find((flight) => flight.id === flightId);

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [shipOption, setShipOption] = useState(
    !flight ? '' : (aircraftList?.find((ac) => ac.id === flight?.aircraft_id)).tail
  );
  const [departureUTCOption, setDepartureUTCOption] = useState(!flight ? '' : getUTCShortDate(flight.departure_timestamp));
  const [overlayOpen, setOverlayOpen] = useState(!flight ? false : true);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [selectedFlightId, setSelectedFlightId] = useState(flight ? flight.id : '');

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const dt30DaysAgo = todayDate.getTime() - (30 * 24 * 60 * 60 * 1000);

  const ship = [DEFAULT_OPTION]
    .concat(
      !aircraftList
        ? []
        : ((!fleetOption)
          ? aircraftList
          : aircraftList
            .filter((aircraft) => aircraft?.aircraft_type?.id === fleetOption)
        ).map((aircraft) => {
          return {
            value: aircraft.tail,
            name: aircraft.tail,
          };
        }).sort((a, b) => a.name.localeCompare(b.name)));

  const filteredFlightList =
    !flightList
      ? []
      : flightList.reduce((arr, flight) => {
        const aircraft = AircraftFactory.getSummaryAircraftById(flight.aircraft_id);
        if (
          (fleetOption === SELECT_OPTION_ALL.value || ((aircraft) && aircraft?.aircraft_type?.id === fleetOption))
          && aircraft?.tail === shipOption
          && (flight?.departure_timestamp > dt30DaysAgo || !flight.departure_timestamp)
        ) {
          arr.push({
            id: flight.id,
            departure_timestamp: flight.departure_timestamp,
            utc: !flight.departure_timestamp ? 'NA' : getUTCShortDate(flight.departure_timestamp),
            flightNumber: flight.number,
            departure_airport: flight.departure_airport,
            destination_airport: flight.destination_airport,
          });
        }
        return arr;
      }, []);

  const departureUTC = [DEFAULT_OPTION]
    .concat(filteredFlightList.reduce((arr, flight) => {
      const x = arr.find(item => item.name === flight.utc)
      if (!x) {
        arr.push({
          dep: flight.departure_timestamp, // For sorting
          value: flight.utc,
          name: flight.utc
        })
      }
      return arr;
    }, []).sort((a, b) => b.dep - a.dep));

  const flights = [DEFAULT_OPTION]
    .concat(filteredFlightList
      .filter((flight) => flight.utc === departureUTCOption)
      .map((flight) => {
        return {
          value: flight.id,
          name: `${!flight.flightNumber || flight.flightNumber === '--' ? 'NA' : flight.flightNumber}
          ${!flight.departure_airport.value.iata || flight.departure_airport.value.iata === '---'
              ? 'NA' : flight.departure_airport.value.iata}-${!flight.destination_airport.value.iata
                || flight.destination_airport.value.iata === '---'
                ? 'NA' : flight.destination_airport.value.iata}`,
        };
      })).sort((a, b) => a.name.localeCompare(b.name));

  const selectedFlight = filteredFlightList.find((fl) => fl.id === selectedFlightId);
  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col data-cy-id={`${idPrefix}-ScreenHeader`} className="title_1"> Flight report </Col>
            <Col md="auto" className={styles.note}>
              Note: Data is available for previous 30 days.
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id={`${idPrefix}-Filters`} noGutters>
            <Col>
              <Row>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setFleetOption(value);
                      setShipOption('');
                      setDepartureUTCOption('');
                      setSelectedFlightId('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Ship`}
                    data-cy-id={`${idPrefix}-FiltersPanel-TailSelect`}
                    label="Tail: "
                    options={ship}
                    value={shipOption}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setShipOption(value);
                      setDepartureUTCOption('');
                      setSelectedFlightId('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Departure-utc`}
                    data-cy-id={`${idPrefix}-FiltersPanel-DepartureDateSelect`}
                    label="Departure UTC Date: "
                    options={departureUTC}
                    value={departureUTCOption}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setDepartureUTCOption(value);
                      setSelectedFlightId('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Flight`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FlightSelect`}
                    label="Flight: "
                    options={flights}
                    value={selectedFlightId}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setSelectedFlightId(value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-CreateReportButton`}
                disabled={!selectedFlightId}
                onClick={() => setOverlayOpen(true)}
              >
                Create report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="Flight report"
        data={<>
          <Row>
            <Col> Fleet </Col>
            <Col> {fleetOption === '' ? 'All' : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name} </Col>
          </Row>
          <Row>
            <Col> Tail </Col>
            <Col> {shipOption === '' ? 'All' : shipOption} </Col>
          </Row>
          <Row>
            <Col> Departure UTC </Col>
            <Col> {departureUTCOption === '' ? 'All' : departureUTCOption} </Col>
          </Row>
          <Row>
            <Col> Flight </Col>
            <Col>
              {selectedFlight
                ? `${!selectedFlight.flightNumber || selectedFlight.flightNumber === '--' ? 'NA' : selectedFlight.flightNumber}
                  ${!selectedFlight.departure_airport.value.iata || selectedFlight.departure_airport.value.iata === '---'
                  ? 'NA' : selectedFlight.departure_airport.value.iata}-${!selectedFlight.destination_airport.value.iata
                    || selectedFlight.destination_airport.value.iata === '---'
                    ? 'NA' : selectedFlight.destination_airport.value.iata}`
                : null}
            </Col>
          </Row>
        </>}
        isOpen={overlayOpen}
        onCancel={() => setOverlayOpen(false)}
        loading={messageOverlayOpen}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getFlightReport(selectedFlightId);
          setMessageOverlayOpen(false);
          setOverlayOpen(false);
        }}
      />
    </>
  );
}

export default Flight;
