import React from "react";
import styles from './styles.module.css';
import PropTypes from 'prop-types';

import { AIRPORTS } from 'constants';

import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';


const CityPickerOverlay = (props) => {
  const { position, isOpen, onCancel, onDone, } = props;

  // Return key of city selected
  const onSelect = (key) => {
    onDone(key);
  }

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button data-cy-id="Dashboard-CityPickerOverlay-CancelButton" className="button_reg" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <Overlay
      data-cy-id="Dashboard-CityPickerOverlay"
      title="Cities:"
      isOpen={isOpen}
      position={position}
      contentClassName={styles.root}
      footer={<Footer />}
      onCancel={onCancel}
    >
      <Row className={styles.listboxContainer}>
        <Col data-cy-id="Dashboard-CityPickerOverlay-CityTable" lg="auto" className={styles.listbox}>
          {
            AIRPORTS.getSortedEnumerationItems()
              .map((item) => {
                const { id, value: { name, iata } } = item;
                return (
                  <Row data-cy-id="Dashboard-CityPickerOverlay-CityRow" key={id} className={`field_1 ${styles.city}`} onClick={() => onSelect(id)}>
                    <Col>{`${name}, (${iata})`}</Col>
                  </Row>
                );
              })
          }
        </Col>
      </Row>
    </Overlay>
  );
}

CityPickerOverlay.propTypes = {
  position: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired
};

export default CityPickerOverlay;
