import React, { useState } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Overlay from 'containers/Overlay';
import LRUDetail from 'view/components/overlays/LRUDetail';

import { Button, Col, Row } from 'react-bootstrap';

const OVERLAY_WIDTH = 400;

const LRUFilterOverlay = (props) => {
  const {
    event,
    isFilterAdded,
    isOpen,
    lrusAndFaultsEnabled,
    onAddFilter,
    onClose,
    onRemoveFilter,
    position,
    rightCorner,
  } = props;

  // lru detail overlay
  const [lruDetailOverlayProps, setLRUDetailOverlayProps] = useState({ id: null, isOpen: false });

  const x = position?.x - OVERLAY_WIDTH;
  const y = position?.y;

  if (!event) return null;

  const title = (!event)
    ? ""
    : event.lru_type
      ? `${event.lru_type.value} ${event.lru_position}:`
      : `${event.id}:`;

  const handleDetailClick = (event) => {
    setLRUDetailOverlayProps({ id: event.lru_id, isOpen: true });
  }

  const handleOnClose = () => {
    setLRUDetailOverlayProps({ id: null, isOpen: false });
  }

  const Footer = () => {
    return (
      <>
        <Row className={styles.footer} noGutters>
          <Col md="auto">
            <Button data-cy-id="EventModal-CancelButton" className={classnames('button_reg')} onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col md="auto">
            {event.lru_type ? (
              <Button
                data-cy-id="EventModal-FilterToEventsButton"
                className={classnames('button_reg')}
                onClick={() =>
                  isFilterAdded
                    ? onRemoveFilter()
                    : onAddFilter(event.lru_type, event.lru_position)
                }
              >
                {isFilterAdded ? 'Remove Filter' : 'Filter to events'}
              </Button>
            ) : null}
          </Col>
          <Col md="auto">
            <Button
              data-cy-id="EventModal-LruDetailsButton"
              className={classnames('button_reg')}
              disabled={!lrusAndFaultsEnabled}
              onClick={() => handleDetailClick(event)}
            >
              LRU Details
            </Button>
          </Col>
        </Row >
        <LRUDetail
          {...lruDetailOverlayProps}
          onClose={handleOnClose}
        />
      </>
    );
  }

  return (
    <Overlay contentClassName={styles.root}
      data-cy-id="EventModal"
      footer={<Footer />}
      isOpen={isOpen}
      onCancel={onClose}
      position={(rightCorner && position) ? ({ x, y }) : position}
      showClose={false}
      title={title} />
  );
}

LRUFilterOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  event: PropTypes.object,
  onClose: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  onAddFilter: PropTypes.func,
  position: PropTypes.object,
  rightCorner: PropTypes.bool,
  isFilterAdded: PropTypes.bool,
};

const lruFilterPropsAreEqual = (prevLRU, nextLRU) => {
  return prevLRU.isOpen === nextLRU.isOpen;
}

export default React.memo(LRUFilterOverlay, lruFilterPropsAreEqual);
