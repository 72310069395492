import React from 'react';
import PropTypes from 'prop-types';

import { LRU_TYPE_SSDCL, NOT_AVAILABLE } from 'constants';
import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import LOPA from 'components/LOPA';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from '../styles.module.css';
import classNames from 'classnames';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-ContentLoadersTableColumn-Lru'
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-ContentLoadersTableColumn-Pos'
  },
  {
    name: 'Bootloader FW',
    key: 'bootloader_fw',
    size: 'small',
    showOutliers: true,
    dataCyClassName: 'Ship-ContentLoadersTableColumn-BootloaderFw'
  },
  {
    name: 'CPU FW',
    key: 'cpu_fw',
    size: 'large',
    showOutliers: true,
    dataCyClassName: 'Ship-ContentLoadersTableColumn-CpuFw'
  }
];

const ContentLoaders = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { content_loaders: contentLoaders } = aircraftLopa;

  const lopaData = {};
  const data = contentLoaders.map(({ id, lru_type: lruType }) => {
    const selected = selectedLru === id;

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, id);
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }

    lopaData[deviceType][id] = {
      'active': true,
      'selected': selected,
    };

    const lru = aircraft.getLRUByTypePosition(lruType.value, id);
    let bootloader_fw;
    let cpu_fw;
    if (lru?.software_version) {
      ({ software_version: { BFW: bootloader_fw, CFW: cpu_fw } } = lru);
    }

    return (
      {
        bootloader_fw: bootloader_fw || NOT_AVAILABLE,
        cpu_fw: cpu_fw || NOT_AVAILABLE,
        id: id,
        lru: LRU_TYPE_SSDCL.value,
        pos: id,
      }
    );
  });

  return (
    <Row className={classNames(styles.root)} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            <Row className={styles.table} noGutters>
              <Col className="h-100">
                <SkyPulseTable
                  title="SSDCL"
                  columns={columns}
                  rows={data}
                  selectedId={selectedLru}
                  onRowClick={onRowClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ContentLoaders.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
}

export default ContentLoaders;
