import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';

import { AIRCRAFT_STATUS_OFFLINE } from 'constants';
import { getFleetHealthHistogramData } from 'utils';

import HealthGauge from 'components/HealthGauge';
import HealthHistogram from 'components/HealthHistogram';
import FleetHealthOverview from 'components/FleetHealthOverview';

import styles from './styles.module.css';

const FleetDetailsPanel = ({
  aircraftList,
  fleetHealth,
}) => {

  let connectedAircraftCount = 0;
  let onlineAircraftCount = 0;
  let totalHealth = 0;

  aircraftList.forEach((aircraft) => {
    const {
      health_score: aircraftHealthScore,
      is_connected: isConnected,
      status,
    } = aircraft;

    if (isConnected) {
      connectedAircraftCount++;
    }

    if (status.id !== AIRCRAFT_STATUS_OFFLINE.id) {
      onlineAircraftCount++;
      if (aircraftHealthScore !== null) {
        totalHealth += aircraftHealthScore;
      }
    }
  });

  // pie chart = average current health of the fleet
  const averageHealth = onlineAircraftCount > 0 ? Math.round(totalHealth / onlineAircraftCount) : 0;

  // histogram = average fleet health at an interval over the last 7 days
  let histogramData;
  if (fleetHealth) {
    histogramData = fleetHealth;
  } else {
    histogramData = getFleetHealthHistogramData(
      aircraftList.map((aircraft) =>
        (aircraft.status.id !== AIRCRAFT_STATUS_OFFLINE.id) ? aircraft.history : []
      )
    );
  }

  return (
    <Row className={styles.root} data-cy-id="Dashboard-FleetDetailsPanel" noGutters>
      <Col>
        <Row className={styles.rightPanelInfo}>
          <Col className={styles.noSpace}>
            <Row>
              <Col data-cy-id="Dashboard-FleetDetailsPanel-Title" className={styles.title}>{aircraftList.length} Aircraft in list</Col>
            </Row>
            <Row>
              <Col>
                <Row data-cy-id="Dashboard-FleetDetailsPanel-Label" className={styles.labels}>
                  <Col md={4}>Connected:</Col>
                  <Col><strong>{connectedAircraftCount}</strong></Col>
                </Row>
                <Row data-cy-id="Dashboard-FleetDetailsPanel-Label" className={styles.labels}>
                  <Col md={4}>Disconnected:</Col>
                  <Col><strong>{aircraftList.length - connectedAircraftCount}</strong></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles.rightPanelInfo}>
          <FleetHealthOverview
            aircraftList={aircraftList}
          />
        </Row>
        <Row className={styles.charts}>
          <Col>
            <Row>
              <Col data-cy-id="Dashboard-FleetDetailsPanel-Title" className={styles.title}>
                <p>Average health ({onlineAircraftCount} aircraft)</p>
              </Col>
            </Row>
            <Row>
              <Col data-cy-id="Dashboard-FleetDetailsPanel-Chart" xs={4} className={styles.col}>
                <HealthGauge
                  healthScore={averageHealth}
                />
                <strong className={styles.chartLabel}>Current</strong>
              </Col>
              <Col data-cy-id="Dashboard-FleetDetailsPanel-Chart" xs={{ size: 7, offset: 1 }} className={styles.col}>
                <HealthHistogram histogramData={histogramData} />
                <strong className={styles.chartLabel}>7 day</strong>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FleetDetailsPanel;
