import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import {
  PATHS,
  AIRCRAFT_CONFIGURATION_TYPE_BLUETOOTH_ZONES,
} from 'constants';
import {
  getUTCDateTime,
} from 'utils';
import styles from './styles.module.css';

const columns = [
  {
    name: 'Item',
    key: 'name',
    size: 'large',
    dataCyClassName: 'Ship-ConfigurableFeaturesTableColumn-Item'
  },
  {
    name: 'Value',
    key: 'value',
    size: 'large',
    dataCyClassName: 'Ship-ConfigurableFeaturesTableColumn-Value'
  },
  {
    name: 'Last changed',
    key: 'lastChanged',
    size: 'small',
    dataCyClassName: 'Ship-ConfigurableFeaturesTableColumn-LastChanged'
  },
];

const Configuration = (props) => {
  const { push, aircraft } = props;

  const data = [];
  aircraft?.configuration.forEach((config) => {
    const latestHistory = config.getLatestHistory();
    let value = aircraft.getLatestConfigValueString(config.configuration_type);
    let created = latestHistory?.created;;
    let name = config.configuration_type.id === AIRCRAFT_CONFIGURATION_TYPE_BLUETOOTH_ZONES.id
      ? `${config.configuration_type.value.name} enabled`
      : config.configuration_type.value.name;
    data.push({
      id: config.configuration_type.id,
      name: name,
      value,
      lastChanged: `${getUTCDateTime(created)} UTC`,
    })
  })

  return (
    <Row className={styles.root} data-cy-id="Ship-ContentPanel" noGutters>
      <Col md={12}>
        <Row className={styles.tableRow} noGutters>
          <Col md={12}>
            <Row className={styles.tableHeaderRow} noGutters>
              <Col className={styles.tableContainer}>
                <SkyPulseTable
                  columns={columns}
                  rows={data}
                  title={"Configurable features"}
                  onRowClick={(id) => {
                    push(`${PATHS.configurationHistory}/${id}`);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default Configuration;
