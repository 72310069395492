import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import classnames from 'classnames';

import { setActiveAircraft, setActiveFlight } from 'actions';

import {
  PATHS,
  ROLE_MARKETING,
} from 'constants';
import { getFlightHeaderText, getLopaBodyType, evaluatePermissions } from 'utils';

import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import LeftNav from 'components/default/LeftNav';
import FlightOverview from 'components/FlightOverview';
import FlightUsage from './FlightUsage';
import FlightSelect from 'components/default/FlightSelect';
import FlightHeatMap from 'components/FlightHeatMap';

import styles from './styles.module.css';


const Flight = (props) => {
  const {
    goBack,
    push,
    rActiveAircraft,
    rActiveFlight,
    rConfiguration,
    rFlightList,
    rUser,
    setActiveAircraft,
    setActiveFlight,
    match: { path },
  } = props;

  const [showFlightSelect, setShowFlightSelect] = useState(false);
  const rssiHeatmapEnabled = rConfiguration.ff_rssi_heatmap;
  const pathParams = path?.split('/');
  let activeMenuItem = pathParams[2] ? pathParams[2] : null;

  // temporary override
  let usageAllowed = evaluatePermissions(rUser, [ROLE_MARKETING.value.name]);
  usageAllowed = true;

  const orderedFlightList = !rFlightList || !rActiveAircraft || !rActiveFlight
    ? []
    : rFlightList
      .filter((flight) => { return flight.aircraft_id === rActiveAircraft.id })
      .sort((a, b) => { return a.departure_timestamp - b.departure_timestamp });

  const flightIndex = !orderedFlightList.length
    ? 0
    : orderedFlightList.map(f => f.id).indexOf(rActiveFlight.id);

  const renderMenuItem = () => {
    switch (activeMenuItem) {
      case 'usage':
        if (!usageAllowed) {
          return renderOverview();
        }
        return <FlightUsage
          key={rActiveFlight.id}
          aircraft={rActiveAircraft}
          flight={rActiveFlight}
          push={push}
        />
      case 'heatmap':
        return <FlightHeatMap
          aircraft={rActiveAircraft}
          flight={rActiveFlight}
          push={push}
        />
      case 'overview':
      default:
        return renderOverview();
    }
  }

  const renderOverview = () => {
    return <FlightOverview
      aircraft={rActiveAircraft}
      flight={rActiveFlight}
      push={push}
    />
  }

  if (!rActiveAircraft || !rActiveFlight || showFlightSelect) {
    return (
      <FlightSelect onCancel={() => {
        if (rActiveFlight) {
          setShowFlightSelect(false)
        } else {
          goBack();
        }
      }} onSelect={(flight, aircraft) => {
        setActiveAircraft(aircraft);
        setActiveFlight(flight);
        setShowFlightSelect(false)
      }} />
    )
  }

  if (rActiveFlight?.aircraft_id !== rActiveAircraft?.id) {
    console.error('Active aircraft is not same as active flight aircraft.')
  }

  const aircraftTypeName = (rActiveAircraft.aircraft_type) ? rActiveAircraft.aircraft_type.value.name : '';
  const NavFlightHeader = `Flight ${rActiveFlight.number}`;

  const NavMap = {
    [NavFlightHeader]: [
      {
        display: 'Overview',
        id: 'overview',
        forbiddenRoles: [ROLE_MARKETING.value.name],
      },
      ...usageAllowed
        ? [{
          display: 'Usage',
          id: 'usage',
        }] : [],
      {
        display: 'Heat map',
        id: 'heatmap',
        hide: !(rssiHeatmapEnabled),
        forbiddenRoles: [ROLE_MARKETING.value.name],
      },
    ]
  }

  if (activeMenuItem === null) {
    Object.values(NavMap[NavFlightHeader]).every((value) => {
      const allowed = evaluatePermissions(rUser, value.allowedRoles, value.forbiddenRoles);
      if (allowed) {
        activeMenuItem = value.id;
        return false;
      }
      return true;
    })
  }

  const goBackward = () => {
    setActiveFlight(orderedFlightList[flightIndex - 1]);
  }

  const goForward = () => {
    setActiveFlight(orderedFlightList[flightIndex + 1]);
  }

  const goBackwardDisabled = flightIndex <= 0;
  const gotForwardDisabled = flightIndex >= orderedFlightList.length - 1;

  return (
    <Row className={classnames(styles.root, getLopaBodyType(aircraftTypeName))} data-cy-id="Flights" noGutters>
      <Col lg="auto" className={styles.leftPanel}>
        <LeftNav
          activeMenuItem={activeMenuItem}
          changeActiveMenu={(id) => push(`${PATHS.flights}/${id}`)}
          navMap={NavMap}
          primaryHeader={
            <div>
              Tail&nbsp;
              <Button
                data-cy-id="Flights-LeftNav-TailLink"
                variant="link"
                className={styles.openAircraftButton}
                onClick={() => { push(PATHS.ship) }}
              >
                {rActiveAircraft.tail}
              </Button>
            </div>
          }
          secondaryHeader={aircraftTypeName}
          testIdPrefix="Flights"
        />
      </Col>
      <Col className={styles.container}>
        <Row className={styles.headerContainer} noGutters>
          <Col data-cy-id="Flights-Header" className={styles.header}>
            {getFlightHeaderText(rActiveFlight)}
          </Col>
          {!rActiveFlight.is_open ?
            (
              <>
                <Col md="auto">
                  <Button
                    className="button_reg"
                    data-cy-id="Flight-FlightReportsButton" onClick={() => push(`${PATHS.reportFlight}/${rActiveFlight.id}`)}
                  >
                    Flight reports
                  </Button>
                  </Col>
                  <Col md="auto">
                  <Button
                    className={`button_reg ${styles.headerButton}`}
                    data-cy-id="Flight-UsageReportsButton" onClick={() => push(`${PATHS.reportUsage}/${rActiveFlight.id}`)}
                  >
                    Usage reports
                  </Button>
                </Col>
              </>
            )
            : null
          }
          <Col md="auto">
            <Button
              className={`button_foc ${styles.headerButton}`}
              data-cy-id="Flights-ChangeFlightButton"
              onClick={() => setShowFlightSelect(true)}
            >
              Change flight
            </Button>
          </Col>
          <Col md="auto">
            <Button
              data-cy-id="Flights-PreviousFlightButton"
              className={`button_foc ${styles.iconButton}`}
              onClick={goBackward}
              disabled={goBackwardDisabled}
            >
              &lt;
            </Button>
          </Col>
          <Col md="auto">
            <Button
              data-cy-id="Flights-NextFlightButton"
              className={`button_foc ${styles.iconButton}`}
              onClick={goForward}
              disabled={gotForwardDisabled}
            >
              &gt;
            </Button>
          </Col>
        </Row>
        {renderMenuItem()}
      </Col>
    </Row>
  );
}

const state = (state) => {
  return ({
    rActiveAircraft: state.activeAircraft,
    rActiveFlight: state.activeFlight,
    rConfiguration: state.configuration,
    rFlightList: state.flightList,
    rUser: state.user,
  });
};

const actions = {
  setActiveAircraft,
  setActiveFlight,
  push,
  goBack,
};

export default connect(state, actions)(Flight);
