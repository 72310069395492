import {
  AIRCRAFT_STATUS,
  TOTAL_TRANSMISSIONS_7_DAY,
} from 'constants';

export function getAircraftStatusFromHealthScore(healthScore) {
  let aircraftStatus;
  AIRCRAFT_STATUS.getSortedEnumerationItems().forEach(({ value: { name, score_min, score_max } }) => {
    if (healthScore >= score_min && healthScore <= score_max) {
      aircraftStatus = name;
    }
  });
  return aircraftStatus;
}

export function getHealthColorFromAircraftStatus(status) {
  if (status) {
    return AIRCRAFT_STATUS[status].color;
  }
  return '';
}

export function getHealthColorFromAircraftHealth(healthScore) {
  return getHealthColorFromAircraftStatus(getAircraftStatusFromHealthScore(healthScore));
}

const sevenDayInterval = (7 * 24 * 60 * 60 * 1000);
const fifteenMinuteInterval = (15 * 60 * 1000);

export function getAircraftHealthHistogramData(history, start = Date.now() - sevenDayInterval) {
  const end = start + sevenDayInterval
  const healthHistoryMap = history
    .filter(({ created }) => created >= start && created <= end)
    .reduce((acc, curr) => { acc[curr.created] = curr.health_score; return acc; }, {});

  const histogramData = [];
  for (let x = 0; x < TOTAL_TRANSMISSIONS_7_DAY; x++) {
    const rangeStart = start + fifteenMinuteInterval * x;
    const rangeEnd = rangeStart + fifteenMinuteInterval;
    const intervalData = Object.keys(healthHistoryMap).filter((ds) => ds >= rangeStart && ds <= rangeEnd);
    const totalEntries = intervalData.length;
    let totalHealth = 0;
    intervalData.forEach((ds) => {
      totalHealth += healthHistoryMap[ds];
      delete healthHistoryMap[ds];
    });
    const intervalHealthScore = (totalEntries > 0) ? Math.round(totalHealth / totalEntries) : 0;

    histogramData.push({
      value: intervalHealthScore,
      fill: getHealthColorFromAircraftHealth(intervalHealthScore),
    });
  }

  return histogramData;
}

export function getFleetHealthHistogramData(historyCollection, end = Date.now()) {
  const histogramData = [];
  const fleetHistogramDataCollection = historyCollection.map((acHistory) => getAircraftHealthHistogramData(acHistory));

  for (let x = 0; x < TOTAL_TRANSMISSIONS_7_DAY; x++) {

    let total = 0;

    const intervalScores = fleetHistogramDataCollection.reduce((scores, acScores) => {
      const score = acScores[x];
      if (score.value) {
        scores.push(score.value);
        total = total + score.value;
      }
      return scores;
    }, []);
    const intervalScoreAverage = (intervalScores.length) ? total / intervalScores.length : 0;

    histogramData.push({
      value: intervalScoreAverage,
      fill: getHealthColorFromAircraftHealth(intervalScoreAverage),
    });
  }
  return histogramData;
}

export function getFlightHealthHistogramData(history) {
  const histogramData = history.map((item) => {
    return {
      value: item.aircraft_health_score,
      fill: getHealthColorFromAircraftHealth(item.aircraft_health_score),
    }
  });
  return histogramData;
}

export function getHeatMapColor(value) {
  if (!value) {
    return;
  }
  value = Math.abs(value);

  const gradient = [
    [0, [128, 1, 1]],
    [35, [128, 1, 1]],
    [37, [133, 6, 6]],
    [39, [148, 21, 21]],
    [41, [163, 36, 36]],
    [43, [165, 36, 39]],
    [45, [169, 48, 40]],
    [47, [182, 79, 50]],
    [49, [197, 112, 62]],
    [51, [212, 148, 76]],
    [53, [224, 181, 89]],
    [55, [234, 212, 102]],
    [57, [242, 237, 113]],
    [59, [252, 247, 118]],
    [61, [214, 234, 106]],
    [63, [105, 208, 77]],
    [65, [67, 208, 66]],
    [67, [61, 197, 142]],
    [69, [29, 79, 173]],
    [73, [21, 47, 159]],
    [80, [0, 0, 127]],
    [100, [0, 0, 127]]
  ];

  let heatMapColor = null;

  for (let i = 0; i < gradient.length; i++) {
    const data = gradient[i];
    if (value === data[0]) {
      heatMapColor = data[1];
      break;
    }
    if (value < gradient[i][0]) {
      const colorArray = [];
      gradient[i - 1][1].map((color, key) => {
        colorArray.push((value * (gradient[i][1][key] - color) / 100) + color);
        return false;
      });
      heatMapColor = colorArray;
      break;
    }
  }
  return heatMapColor;
}
