/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

export const useForceRerender = () => {
  const [, setValue] = useState(0);
  return () => {
    setValue(value => value + 1);
  }
};

export const useIsElementVisible = (target, options = undefined) => {
  const [isVisible, setIsVisible] = useState(false);
  const forceUpdate = useForceRerender();

  useEffect(() => {
    // edge-case: force render component twice during first mount, so
    // useIsElementVisible references last element
    forceUpdate();
  }, []);

  useEffect(() => {
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(handleVisibilityChange, options);
    observer.observe(target);
    return () => observer.unobserve(target);
  }, [target, options]);

  const handleVisibilityChange = ([entry]) => {
    setIsVisible(entry.isIntersecting && Math.floor(entry.intersectionRatio) === 1);
  }

  return isVisible;
};
