import React from "react";
import styles from './styles.module.css';
import PropTypes from 'prop-types';

import {
  FILTER_EVENT_TYPE_OPTIONS,
} from 'constants';
import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';
import classnames from 'classnames';
import ButtonItem from 'components/inputs/ButtonItem';


const EventTypeFilterOverlay = (props) => {
  const {
    position,
    isOpen,
    onCancel,
    onDone,
    selectedEvents,
    setSelectedEvents,
  } = props;

  // Return key of city selected
  const onChange = (key) => {
    const newFilters = { ...selectedEvents };
    newFilters[key] = !newFilters[key];
    setSelectedEvents(newFilters);
  }

  const selectAll = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      newFilters[item.value] = true;
    });
    setSelectedEvents(newFilters);
  }

  const selectNone = () => {
    setSelectedEvents({});
  }

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button className="button_reg" data-cy-id="EventFilterOverlay-CancelButton" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id="EventFilterOverlay-DoneButton"
            onClick={() => onDone(selectedEvents)}
            disabled={Object.keys(selectedEvents).filter((key) => selectedEvents[key]).length < 1}
          >
            Done
            </Button>
        </Col>
      </Row>
    )
  }

  return (
    <Overlay
      data-cy-id="Events-EventTypeFilterOverlay"
      isOpen={isOpen}
      position={position}
      contentClassName={styles.root}
      footer={<Footer />}
      onCancel={onCancel}
      showClose={false}
    >
      <Row className={styles.header} noGutters>
        <span> Select: </span>
        <Button data-cy-id="EventFilterOverlay-AllButton" className={styles.link} onClick={selectAll}>All</Button>
        <span> | </span>
        <Button data-cy-id="EventFilterOverlay-NoneButton" className={styles.link} onClick={selectNone}>None</Button>
      </Row>
      <ul className={styles.listboxContainer}>
        {
          FILTER_EVENT_TYPE_OPTIONS
            .map((item, index) => {
              const { name, value } = item;
              return (
                <li key={index} className={classnames('d-flex align-items-center py-1', styles.eventFilter, selectedEvents[value] && styles.checked)} md="auto">
                  <ButtonItem
                    data-cy-class="Events-EventTypeFilterOverlay-FilterCheckbox"
                    label={name}
                    value={value}
                    type="checkbox"
                    classname={'m-1'}
                    isChecked={selectedEvents[value] || false}
                    onChange={() => onChange(value)}
                    idPrefix={value}
                  />
                </li>
              );
            })
        }
      </ul>
      <br />
    </Overlay>
  );
}

EventTypeFilterOverlay.propTypes = {
  position: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default EventTypeFilterOverlay;
