import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { evaluatePermissions } from 'utils';

const modes = {
  "HIDDEN": "hidden",
  "DISABLED": "disabled"
}

const IfCan = (props) => {
  const {
    alternateContent,
    children,
    disableClassName,
    mode,
    override,
    permissions,
    forbiddenRoles,
    allowedRoles,
    user,
  } = props;

  const allowed = override !== undefined ? override : evaluatePermissions(user, allowedRoles, forbiddenRoles, permissions);
  const classNames = allowed ? '' : disableClassName;

  return (
    <>
      {
        allowed
          ? <>{children}</>
          :
          alternateContent
            ? <>{alternateContent}</>
            :
            mode === modes.HIDDEN
              ? null
              : (
                <div
                  className={classNames}
                  onClickCapture={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                >
                  {children}
                </div>
              )
      }
    </>
  );
};

IfCan.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  alternateContent: PropTypes.any,
  children: PropTypes.any.isRequired,
  disableClassName: PropTypes.string,
  forbiddenRoles: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.string,
  override: PropTypes.bool,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

IfCan.defaultProps = {
  disableClassName: 'disabled',
  mode: modes.HIDDEN,
  override: undefined,
}

const state = (state) => {
  return ({
    user: state.user,
  });
};

const actions = {};

export default connect(state, actions)(IfCan);
